const axios = require('axios');


module.exports = {
  DoSql: function (sql,hdata,props)  
  {           
    let hudata = Array.isArray(hdata) ? {data:hdata} : hdata;
    hudata.secid = JSON.parse(localStorage.getItem('secid'))
    hudata.clsecid = JSON.parse(localStorage.getItem('clsecid'))
    hudata.function = sql;
    let authOptions = {
        method: 'post',
        // url: ' https://app.brutax.com/server/index.php',     
        url: 'http://localhost:3001/server/index.php',       
        data: JSON.stringify(hudata),
        headers: {
          'Content-Type': 'application/json'
        },
        json: true,
    };
    return new Promise(
      function (resolve, reject) {
        axios(authOptions)
          .then((response) => {
            if (response.data && response.data.allowed === false)
            {
              if (props)
              {
                props.history.push('/authentication/login');
              }
              else
              {
                resolve(null);                
              }
            }        
            else
            {
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject({error:'Fout opgetreden'});
          })
      });
  },


  ConnectMail: function ()  
  {
   return null;
    var h = "400px", w = "700px", l = "400px", t = "100px", r = "yes", c = "yes", s = "no";
    t = window.screenTop+50+'px';
    l = window.screenLeft + 50 + 'px';
    h =  window.outerHeight - 50 + 'px';
    w =  window.outerwidth - 50 + 'px';

    var modelFeature = "height = " + h + ",width = " + w + ",left=" + l + ",top=" + t + ",model=yes,alwaysRaised=yes,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable= " + r + ",celter=" + 
                        c + ",status=" + s;
    var model = window.open("https://app.brutax.com/server/oauth.php", "", modelFeature, null);
    return model;
  },


  ConnectMailold: function ()  
  {
    return;
    var h = "400px", w = "700px", l = "400px", t = "100px", r = "yes", c = "yes", s = "no";
    t = window.screenTop+50+'px';
    l = window.screenLeft + 50 + 'px';
    h =  window.outerHeight - 50 + 'px';
    w =  window.outerwidth - 50 + 'px';

    var modelFeature = "height = " + h + ",width = " + w + ",left=" + l + ",top=" + t + ",model=yes,alwaysRaised=yes,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable= " + r + ",celter=" + 
                        c + ",status=" + s;
    var model = window.open("https://app.brutax.com/server/oauth.php", "", modelFeature, null);
    return model;

//     window.openDialog(" https://app.brutax.com/server/oauth.php", "dlg", "", "", 6.98);
//    window.open(' https://app.brutax.com/server/oauth.php',"appauth");      
  },




  DoSqlBody: function (sql,hdata,props)  
  {
//        url: ' https://app.brutax.com/server/index.php',
//        url: ' https://app.brutax.com/server/index.php',            


    hdata.set('secid', JSON.parse(localStorage.getItem('secid')))
    hdata.set('clsecid', JSON.parse(localStorage.getItem('clsecid')))
    hdata.set('function', sql)
    let authOptions = {
        method: 'post',
        url: 'http://localhost:3001/server/index.php',
        // url: ' https://app.brutax.com/server/index.php',            
        data: hdata,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
    };
    return new Promise(
      function (resolve, reject) {
        axios(authOptions)
          .then((response) => {
            if (response.data && response.data.allowed === false)
            {
              if (props)
              {
                props.history.push('/authentication/login');
              }
              else
              {
                resolve(null);                
              }
            }        
            else
            {
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject({error:'Fout opgetreden'});
          })
      });
  },

  SetAuth: function (sql,hdata)  
  {

    let hudata = hdata;
    console.log("Hudate is:", hudata);
    console.log("sql is:", sql);
    hudata.secid = JSON.parse(localStorage.getItem('secid'))
    hudata.clsecid = JSON.parse(localStorage.getItem('clsecid'))
    hudata.function = sql;

    let authOptions = {
        method: 'post',
        // url: ' https://app.brutax.com/server/index.php', 
        url: 'http://localhost:3001/server/index.php',           
        data: JSON.stringify(hudata),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        json: true,
    };
    console.log('AuthOption in SetAuth', authOptions);
    return authOptions;
  },



};
