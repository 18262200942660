import React from 'react';
import { connect } from 'react-redux'
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col,
	Button
} from 'reactstrap';

import img1 from '../../assets/images/brutlog.png';
import img2 from '../../assets/images/background/login-register.jpg';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


import { authenticationService } from '../../jwt/_services';
const sidebarBackground = {
	backgroundImage: "url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const SqlFunction = require("../../functions/sql_functions");

const mapStateToProps = state => ({
	...state
});
var loginteller = 0;
class Login extends React.Component {
	render() {
		return <div className="">
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box on-sidebar">
					<div id="loginform">
						<div className="logo">
							<span className="db"><img src={img1} alt="logo" /></span>
							<h5 style={{marginTop: '50px'}} className="font-medium mb-3">Inloggen Brutax </h5>
						</div>
						<Row>
							<Col xs="12">
								<Formik
				                    initialValues={{
				                        username: '',
				                        password: ''
				                    }}
				                    validationSchema={Yup.object().shape({
				                        username: Yup.string().required('Loginnaam is verplicht'),
				                        password: Yup.string().required('Paswoord is verplicht')
				                    })}
				                    onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
										console.log(setStatus());
				                        setStatus();
										console.log('After Status');
										console.log(username+password);
				                        authenticationService.login(username, password, this.props)
				                            .then(
				                                user => {
				                                	if (this.props.user.userData.allowed === undefined)
				                                	{
				                                    	const { from } =  { from: { pathname: "/userdashboard" } };
				                                    	// SqlFunction.ConnectMail();
														console.log('AbraCadraBra in then login');
														console.log(user);
				                                    	this.props.history.push(from);
				                                    }
				                                    else
				                                    {
														console.log('props checken', this.props.userData)
					                                    setSubmitting(false);
														console.log('AbraCadraBra Naam or paswoord is onjuist');
					                                    setStatus('Naam of or paswoord is onjuist');
					                                    loginteller = loginteller + 1;
					                                    if (loginteller < 5)
					                                    {
					                                    	authenticationService.logout(this.props)
															setTimeout(function(){ setStatus(""); }, 10000);					                                    	
					                                    }
				                                    }
				                                },
				                                error => {
													console.log('AbraCadraBra Naam of paswoord is onjuist2');
				                                    setSubmitting(false);
				                                    setStatus(error);
				                                }
				                            );
				                    }}
				                    render={({ errors, status, touched, isSubmitting, setStatus }) => (
										<Form className="mt-3" id="loginform">
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ti-user"></i>
													</InputGroupText>
												</InputGroupAddon>
												
												<Field name="username" type="text"  className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
		                                		<ErrorMessage name="username" component="div" className="invalid-feedback" />
											</InputGroup>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ti-pencil"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
		                                		<ErrorMessage name="password" component="div" className="invalid-feedback" />

											</InputGroup>
											<Row className="mb-3">
												<Col xs="12">
													<Button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Login</Button>
												</Col>
											</Row>
											{status &&
		                                		<div className={'alert alert-danger'}>Verkeerde gebruiker of pazswoord</div>
		                            		}
										</Form>
									)}
                				/>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default connect(mapStateToProps)(Login)
