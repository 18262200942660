import React, { createRef } from 'react';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grow from '@material-ui/core/Grow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux'
import {Button} from 'reactstrap';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Timeline from "../../components/TimeLine.jsx";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditCheck from "../../components/editcheck.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditSelect from "../../components/editselect.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import PopAddSupplier from "../suppliers/PopAddSupplier.jsx";
import PopJobOpenOnkosten from "./PopJobOpenOnkosten.jsx";
import PopJobContrAanv from "./PopJobContrAanv.jsx";
import PopDetailDok from "./PopDetailDok.jsx";
import PopJobDubbelFactuur from "./PopJobDubbelFactuur.jsx";
import LinkPopJobAanvrNum from "./PopJobAanvrNum.jsx";
import PopJobEditBoetes from "./PopJobEditBoetes.jsx";
import PopJobEditDouane from "./PopJobEditDouane.jsx";
import PopJobEditOnkosten from "./PopJobEditOnkosten.jsx";
import PopJobEditVatRefundOnk from "./PopJobEditVatRefundOnk.jsx";
import PopInvoice from "../invoices/PopInvoice.jsx";
import PopJobEditDfv from "./PopJobEditDfv.jsx";
import PopJobEditJvl from "./PopJobEditJvl.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import Validation from "../../functions/validation.js";
import {setSubTitle,zetview,zetviewleeg,clickview, resetview,zetpopup } from "../../redux/settings/action";
import { GetAccount, GetContAccount } from "../../redux/settings/accountActions";
import { GetKantoor, GetContKantoor} from "../../redux/settings/kantoorActions";
import { GetUser } from "../../redux/settings/userActions";
import {GetRegistration} from "../../redux/settings/registrationActions";
import {AllJobRegNumAcc} from "../../redux/settings/jobsActions";
import moment from 'moment'
import Dropzone from 'react-dropzone';
import { JobDefMainAllCodes } from '../../redux/settings/jobsActions';

const jobdropid = GenFunc.MakeId()
const jobframeid = GenFunc.MakeId()
const jobformid = GenFunc.MakeId()

const PdfScanVat = GenFunc.MakeId()
const PdfScanVatName = GenFunc.MakeId()

const PdfListing = GenFunc.MakeId()
const PdfListingKlant = GenFunc.MakeId()
const PdfOnkListing = GenFunc.MakeId()
const PdfOnkListingName = GenFunc.MakeId()

const XmlListing = GenFunc.MakeId()

var dropdoc = null;
var gridapi = null;

const descriptionElementRef = React.useRef<HTMLElement>(null);

const dropzoneRef = createRef();
const openDialog = () => {
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};


var workflowstorie = null;
var regisobj = null;
var refertenumobj = null;
var userobj = null;
var accountobj = null;
var kantoorobj = null;
var countryobj = null;
var corraangobj = null;
var corrcontroleobj = null;

var save = false;
var dropworkflow = null;
var rowpinnend = null;


class PopJobAanvrNum extends React.Component {
    state = {
        DownloadData:{id:''},
        tabonkostnoedit:null,
        tabonkostedit:null,
        data: this.props.data.data,
        workflows: this.props.data.workflows,
        onkosten: this.props.data.onkosten,
        onkostenkol: this.props.data.onkostenkol,
        vatrefund:this.props.data.vatrefund, 
        vatrefundkol:this.props.data.vatrefundkol,
        boetes: this.props.data.boetes,
        boeteskol: this.props.data.boeteskol,
        dfvdata: this.props.data.dfvdata,
        dfvdatalijst:this.props.data.dfvdatalijst,
        dfvkol: this.props.data.dfvkol,
        jvldata: this.props.data.jvldata,
        jvlkol: this.props.data.jvlkol,
        douanes: this.props.data.douanes,
        douaneskol: this.props.data.douaneskol,
        linkonkosten: this.props.data.linkonkosten,
        linkcontraang: this.props.data.linkcontraang,
        edit:false,
        filename:'',
        save:false,
        subsave:true,
        PopUpScreen:null,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        workflowstories:[],
        changewfindex:null,
        nivo: (this.props.nivo) ? this.props.nivo : 0,
        munt:'',

    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.handleLinkPdf = this.handleLinkPdf.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDfvImport = this.handleDfvImport.bind(this);
        this.handleDfvDoc = this.handleDfvDoc.bind(this);
        this.handleJvlImport = this.handleJvlImport.bind(this);
        this.handleJvlDoc = this.handleJvlDoc.bind(this);
        this.handlePdfScanVat = this.handlePdfScanVat.bind(this);

        this.handlePdfListing = this.handlePdfListing.bind(this);
        this.handlePdfListingKlant = this.handlePdfListingKlant.bind(this);

        this.handleXmlListing = this.handleXmlListing.bind(this);


        this.DokClick = this.DokClick.bind(this);

        this.getStories = this.getStories.bind(this);
        this.getNewStories = this.getNewStories.bind(this);
        this.handleAfhandel = this.handleAfhandel.bind(this);
        this.ClickDocument = this.ClickDocument.bind(this);
        this.DeleteDocument = this.DeleteDocument.bind(this);
        this.AddDocument = this.AddDocument.bind(this);
        this.getFields = this.getFields.bind(this);
        this.WorkFlowClick = this.WorkFlowClick.bind(this)
        this.WorkFlowEnter = this.WorkFlowEnter.bind(this)
        this.onDrop = this.onDrop.bind(this);
        this.WorkFlowClickverder = this.WorkFlowClickverder.bind(this)
        this.handleFlowCancel = this.handleFlowCancel.bind(this)
        this.SetObject = this.SetObject.bind(this)
        this.onGridReady = this.onGridReady.bind(this);
        this.SubOnkostenChanged = this.SubOnkostenChanged.bind(this);
        this.SubOnkostenCheck = this.SubOnkostenCheck.bind(this);
        this.SubOnkostenCellClick = this.SubOnkostenCellClick.bind(this);

        this.DfvLijstChanged = this.DfvLijstChanged.bind(this);
        this.DfvLijstCheck = this.DfvLijstCheck.bind(this);
        this.DfvLijstCellClick = this.DfvLijstCellClick.bind(this);


        this.CheckSaveOnkost = this.CheckSaveOnkost.bind(this);
        this.SubBoetesChanged = this.SubBoetesChanged.bind(this);
        this.SubBoetesCheck = this.SubBoetesCheck.bind(this);
        this.SubBoetesCellClick = this.SubBoetesCellClick.bind(this);
        this.CheckSaveBoetes = this.CheckSaveBoetes.bind(this);

        this.ClosePopBoeteEdit = this.ClosePopBoeteEdit.bind(this);


        this.DeleteLinkJob = this.DeleteLinkJob.bind(this);
        this.DeleteContrAanvr = this.DeleteContrAanvr.bind(this);


        this.SubDouanesChanged = this.SubDouanesChanged.bind(this);
        this.SubDouanesCheck = this.SubDouanesCheck.bind(this);
        this.SubDouanesCellClick = this.SubDouanesCellClick.bind(this);
        this.CheckSaveDouanes = this.CheckSaveDouanes.bind(this);
        this.handleOnkListing = this.handleOnkListing.bind(this);




        this.addRecord = this.addRecord.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.TableOnkostEdit = this.TableOnkostEdit.bind(this);
        this.TableVatRefundOnkostEdit = this.TableVatRefundOnkostEdit.bind(this);


        this.TableBoeteEdit = this.TableBoeteEdit.bind(this);
        this.TableDouaneEdit = this.TableDouaneEdit.bind(this);
        this.TableDfvEdit = this.TableDfvEdit.bind(this);
        this.TableJvlEdit = this.TableJvlEdit.bind(this);

        this.GetOnkosten = this.GetOnkosten.bind(this);
        this.GetOpenAang = this.GetOpenAang.bind(this);


        this.GoToLinkJob = this.GoToLinkJob.bind(this);
        this.CreaVoi = this.CreaVoi.bind(this);


        this.GoToMain = this.GoToMain.bind(this);

        this.CreateExtAang = this.CreateExtAang.bind(this);

        this.handleJobRegJa = this.handleJobRegJa.bind(this)
        this.handleJobVatJa = this.handleJobVatJa.bind(this)

        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.ClosePopUpOnk = this.ClosePopUpOnk.bind(this);
        this.CheckOnhold = this.CheckOnhold.bind(this)

        this.openInvoice = this.openInvoice.bind(this);
        this.closePopInvoice = this.closePopInvoice.bind(this);
        this.GetTarief = this.GetTarief.bind(this)
        this.GetId = this.GetId.bind(this)

    }
    GetId()
    {
       return this.state.data.id;
    }
    async CheckOnhold()
    {
        if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
        {
          // Is nummer in aanvraag ? en geen nummer
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Deze klant staat onhold, geen verwerkingen toegelaten</h1>
                    <h1>{this.props.data.data.infohold}</h1>
                    <button
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                );
                }
            });            
        }

    }

    async componentDidMount()
    {
      this.CheckOnhold();
      await this.setState({data:this.props.data.data,workflows:this.props.data.workflows, 
                      onkosten:this.props.data.onkosten, onkostenkol:this.props.data.onkostenkol,         
                      vatrefund:this.props.data.vatrefund, vatrefundkol:this.props.data.vatrefundkol,
                      boetes: this.props.data.boetes, boeteskol: this.props.data.boeteskol,
                      dfvdata: this.props.data.dfvdata,dfvkol: this.props.data.dfvkol,
                      dfvdatalijst:this.props.data.dfvdatalijst,
                      jvldata: this.props.data.jvldata,jvlkol: this.props.data.jvlkol,
                      douanes: this.props.data.douanes,  douaneskol: this.props.data.douaneskol,linkonkosten: this.props.data.linkonkosten,
                      linkcontraang: this.props.data.linkcontraang,edit: (this.props.data.data.id === 0) ? true: false, changewfindex:(this.props.data.data.id === 0) ? 0: null})
      if (this.props.data && this.props.data.data && this.props.data.data.id && this.props.data.data.id.length > 3)
      {
          this.props.dispatch(zetview(this.props.data.data.description, this.props.data.data.id, 'JOB',null));      
      }

      if (this.props.data && this.props.data.data )
      {
        await this.GetTarief(this.props.data.data);
      }

      if (this.props.data && this.props.data.data && this.props.data.data.munt)
      {
        let munt = GenFunc.GetMuntPrefix(this.props.data.data.munt)
        this.setState({munt:munt})
      }
      if (this.props.data && this.props.data.data && this.props.data.data.account_id)
      {
          await this.props.dispatch(AllJobRegNumAcc(this.props,this.props.data.data.account_id,this.props.data.data.country));
      }
      this.getStories()

    }

// ef030d2993930a7b6c95bd2b32ed45989c61f
//2603e329859e83860db1d11b6acca57d8ea88
    async GetTarief(data)
    {
      let tariefdata = await SqlFunction.DoSql('GetTarief',data);

      if (tariefdata && data.fact_id.length < 4)
      {
        if (data.aang_optie === '3')
        {
          data.tarief = tariefdata.nihil;
        }
        else
        {
          data.tarief = tariefdata.tarief;
        }
        if (data.job_id === 'ef030d2993930a7b6c95bd2b32ed45989c61f' || data.job_id ===   '2603e329859e83860db1d11b6acca57d8ea88')
        {
          let num_tot_vat_btw = (data && data.tot_vat_btw && data.tot_vat_btw.constructor === String) ? parseFloat(data.tot_vat_btw) : data.tot_vat_btw;
          if (isNaN(num_tot_vat_btw)) {num_tot_vat_btw = 0}
          let num_tarief = (tariefdata && tariefdata.tarief && tariefdata.tarief.constructor === String) ? parseFloat(tariefdata.tarief) : tariefdata.tarief;
          if (isNaN(num_tarief)) {num_tarief = 0}
          data.vat_tarief = num_tarief; 
          data.vat_basisfact = num_tot_vat_btw; 
          let vatber = num_tot_vat_btw * (num_tarief / 100)
          let tegoed = num_tot_vat_btw

          let num_terug_bedr = (data && data.terug_bedr && data.terug_bedr.constructor === String) ? parseFloat(data.terug_bedr) : data.terug_bedr;
          if (isNaN(num_terug_bedr)) {num_terug_bedr = 0}


          if (num_terug_bedr > 0)
          {
            vatber = num_terug_bedr * (num_tarief / 100)
            tegoed = num_terug_bedr
          }
          if (tariefdata.staffel)
          {
              for (var i = 0; i < tariefdata.staffel.length; i++) 
              { 
                let rij = tariefdata.staffel[i];
                let mintarief = (rij && rij.mintarief.constructor === String) ? parseFloat(rij.mintarief) : rij.mintarief;
                if (isNaN(mintarief)) {mintarief = 0}
                let maxtarief = (rij && rij.maxtarief.constructor === String) ? parseFloat(rij.maxtarief) : rij.maxtarief;
                if (isNaN(maxtarief)) {maxtarief = 0}
                num_tarief = (rij && rij.tarief.constructor === String) ? parseFloat(rij.tarief) : rij.tarief;
                if (isNaN(num_tarief)) {num_tarief = 0}

                if (tegoed > mintarief)
                {
                   if (maxtarief > 0 && tegoed <= maxtarief)
                   {
                     vatber = tegoed * (num_tarief / 100)
                     data.vat_tarief = num_tarief; 
                   }  
                   else
                   {
                      if (maxtarief < mintarief)
                      {
                        vatber = tegoed * (num_tarief / 100)
                        data.vat_tarief = num_tarief; 
                      }

                   }
                }
              }
          }
          let mintar = (tariefdata  && tariefdata.mintar && tariefdata.mintar.constructor === String) ? parseFloat(tariefdata.mintar) : tariefdata.mintar;
          if (isNaN(mintar)) {mintar = 0}

          if (vatber < mintar)
          {
            vatber = mintar;
          }

          let extra = (data && data.vat_factextra && data.vat_factextra.constructor === String) ? parseFloat(data.vat_factextra) : data.vat_factextra;
          if (isNaN(extra)) {extra = 0}

          data.vat_facttot = vatber + extra;         
        }
      }
      SqlFunction.DoSql('ChgJob',data);
      return tariefdata;

    }

    onGridReady(params)
    {
      gridapi = params;
    }

    async GoToLinkJob(waarde) {
      let hdata = {};
      hdata.id = waarde;
      let jobdata = await SqlFunction.DoSql('GetJob', hdata);
      if (jobdata && jobdata.data)
      {
        let title = 'Jobdetail - ' + jobdata.data.description 

        this.props.dispatch(zetview(this.state.data.description, this.state.data.id, 'JOB',this));
        this.setState({PopUpScreen:(<LinkPopJobAanvrNum open={true} history={this.props.history} nivo={this.state.nivo + 1} data={jobdata} close={this.ClosePopUp} title={title}/>)});
        this.props.dispatch(clickview({ id: hdata.id, vorm: 'JOB' }));
      }
      return;
    }

    async GoToMain(vorm,data) {

      this.props.dispatch(zetview(this.state.data.description, this.state.data.id, 'JOB',this));
      if (this.props.close) {
         await this.props.dispatch(resetview())
         await this.props.dispatch(zetpopup(null));
      }

      switch (vorm) 
      {
        case 'ACC':
          this.props.dispatch(setSubTitle('Klantenfiche'))
          this.props.dispatch(clickview({id:data.account_id, vorm:'ACC'}));
          await this.props.dispatch(GetAccount(data.account_id))
          await this.props.dispatch(GetContAccount(data.account_id))
          this.props.history.push('/account');
          break;

        case 'USR':
          this.props.dispatch(setSubTitle('Gebruiker'))
          this.props.dispatch(clickview({id:data.user_id, vorm:'USR'}));
          await this.props.dispatch(GetUser(data.user_id))
          this.props.history.push('/user');
          break;

        case 'KANT':
          this.props.dispatch(setSubTitle('Kantoor'))
          this.props.dispatch(clickview({ id: data.kantoor_id, vorm: 'KANT' }));
          await this.props.dispatch(GetKantoor(data.kantoor_id))
          await this.props.dispatch(GetContKantoor(data.kantoor_id))
          this.props.history.push('/kantoor');
          break;  

        case 'REG':
          this.props.dispatch(setSubTitle('Registratie'))
          this.props.dispatch(clickview({ id: data.registration_id, vorm: 'REG' }));
          await this.props.dispatch(GetRegistration(data.registration_id))
          this.props.history.push('/registration');
          break;  


        default:    
          break;
      }     
      return;
    }

    async closePopInvoice() {

      this.setState({ PopUpScreen: null });
      this.props.dispatch(resetview())
      this.props.dispatch(zetpopup(null));
    }

    async openInvoice(waarde){
      let hdata = {};
      hdata.id = waarde.target.id;
      let invoicedata = await SqlFunction.DoSql('GetFactuurAccount', hdata);
      hdata = {};
      hdata.id = invoicedata.data.taalcode;
      let invoicelanguagedata = await SqlFunction.DoSql('GetFactuuropbouw', hdata);
      let allinvoicedata = {
        invoicedata: invoicedata.data,
        invoicelanguagedata: invoicelanguagedata.data,
        jobdata: this.props.data
      }
      let title = 'Factuur - ' + allinvoicedata.invoicedata.factnr;
      window.scrollTo(0, 0);
      await this.props.dispatch(JobDefMainAllCodes(this.props));
      this.props.dispatch(zetpopup((<PopInvoice open={true} data={allinvoicedata} close={this.closePopInvoice} title={title} />)));
    }

    async ClosePopUp() 
    {
      this.setState({PopUpScreen:null})
      this.props.dispatch(clickview({ id: this.state.data.id, vorm: 'JOB' }));
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol,jvldata: jobdata.jvldata, jvlkol: jobdata.jvlkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async ClosePopUpOnk() 
    {
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes,  dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol,jvldata: jobdata.jvldata, jvlkol: jobdata.jvlkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    addRecord(params, waarde) 
    {
      var colId = params.props.column.getId();
      if (colId === 'supplier_txt')
      {
        this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde} toevobject={params} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
      }
    }

    getFilter(params) 
    {
      var colId = params.props.column.getId();
      var filtar = []
      if (colId === 'type_onkost_txt')
      {
        filtar.push({field:'land',value:this.state.data.country})
        params.setFilter(filtar);
      }
    }

    TableOnkostEdit() 
    {
      if (this.state.onkostenkol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditOnkosten country={this.state.data.country} jobid={this.state.data.id} jobname={this.state.data.description} kol={this.state.onkostenkol} open={true} data={this.state.onkosten} close={this.ClosePopBoeteEdit} title='Te recupereren onkosten' />) });
      }    
    }

    TableVatRefundOnkostEdit(workflowstorie) 
    {
      if (this.state.vatrefundkol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditVatRefundOnk dropworkflow={workflowstorie} dropdata={this.state.data} country={this.state.data.country} jobid={this.state.data.id} jobname={this.state.data.description} kol={this.state.vatrefundkol} open={true} data={this.state.vatrefund} close={this.ClosePopBoeteEdit} title='Vatrefund te verwerken documenten' />) });
      }    
    }

    TableBoeteEdit() 
    {
      if (this.state.boeteskol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditBoetes jobid={this.state.data.id} kol={this.state.boeteskol} open={true} data={this.state.boetes} close={this.ClosePopBoeteEdit} title='Ingave Boetes' />) });
      }    
    }

    DokClick()
    {
      this.setState({ edit:false, PopUpScreen: (<PopDetailDok open={true} data={this.state.data} close={this.ClosePopBoeteEdit} title='Document Job' />) });
    }

    TableDfvEdit() 
    {
      if (this.state.dfvkol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditDfv jobid={this.state.data.id} kol={this.state.dfvkol} open={true} data={this.state.dfvdata} close={this.ClosePopBoeteEdit} title='Dfv Berekening' />) });
      }    
    }

    TableJvlEdit() 
    {
      if (this.state.jvlkol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditJvl jobid={this.state.data.id} kol={this.state.jvlkol} open={true} data={this.state.jvldata} close={this.ClosePopBoeteEdit} title='Jvl Berekening' />) });
      }    
    }

    TableDouaneEdit() 
    {
      if (this.state.douaneskol) 
      {
        this.setState({ edit:false, PopUpScreen: (<PopJobEditDouane jobid={this.state.data.id} kol={this.state.douaneskol} open={true} data={this.state.douanes} close={this.ClosePopBoeteEdit} title='Ingave Douaneformaliteiten' />) });
      }    
    }

    SubOnkostenChanged(params,waarde,that) 
    {
    }                        

    DfvLijstChanged(params,waarde,that) 
    {
    }                        

    SubBoetesChanged(params,waarde,that) 
    {
    }                        

    SubDouanesChanged(params,waarde,that) 
    {
    }                        

    CheckSaveOnkost(params) {
      let terug = false
      if (params.props.data.totaal > 0 && params.props.data.supplier_id.length > 4 && params.props.data.type_onkost.length > 4)
      {
          terug = false;
      }
      else
      {
          terug = true;
      }
      if (terug === false)
      {
          terug = Validation.ValidCheck('JobOnkost', params.props.data.factnum, 'factnum', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.factdat, 'factdat', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.type_onkost, 'type_onkost', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.type_onkost, 'supplier_id', params, gridapi);
      }

      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
      params = {
          force: true,
          columns: ['factnum'],
      };
      gridapi.api.refreshCells(params)          
    }

    async ClosePopBoeteEdit()
    {
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.GetTarief(jobdata.data);
      SqlFunction.DoSql('ChgJob',jobdata.data);


      this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol, dfvdatalijst:jobdata.dfvdatalijst, dfvdata:jobdata.dfvdata, dfvkol:jobdata.dfvkol,jvldata:jobdata.jvldata, jvlkol:jobdata.jvlkol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol})          
      this.WorkFlowClickverder(this.state.changewfindex,true,true)
      this.WorkFlowClickverder(0,true,true)
      this.setState({PopUpScreen:null, changewfindex:null})
      this.getStories()


    }

    CheckSaveBoetes(params) {
      let terug = false
      if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 4 && params.props.data.datum.length > 4)
      {
          terug = false;
      }
      else
      {
          terug = true;
      }
      if (terug === false)
      {
          terug = Validation.ValidCheck('JobBoetes', params.props.data.datum, 'datum', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobBoetes', params.props.data.nummerplaat, 'nummerplaat', params, gridapi);
      }
      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
    }

    CheckSaveDouanes(params) {
      let terug = false
      if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 4 && params.props.data.datum.length > 4)
      {
          terug = false;
      }
      else
      {
          terug = true;
      }
      if (terug === false)
      {
          terug = Validation.ValidCheck('JobDouanes', params.props.data.datum, 'datum', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobDouanes', params.props.data.nummerplaat, 'nummerplaat', params, gridapi);
      }
      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
    }

    async SubOnkostenCheck(params,waarde) {
      var colId = params.props.column.getId();
      params.props.data.save = true;
      let terug = false
      let totaal = 0
      let bedrag = 0
      let btw = 0
      let mvh = 0
      if (params.props.data.btw) 
      {
        if (params.props.data.btw.constructor === String)
        {
          btw = parseFloat(params.props.data.btw);           
          if (isNaN(btw)) {btw = 0}
        }
        else
        {
          btw = params.props.data.btw;           
        }
      }
      if (params.props.data.mvh) 
      {
        if (params.props.data.mvh.constructor === String)
        {
          mvh = parseFloat(params.props.data.mvh);           
          if (isNaN(mvh)) {mvh = 0}
        }
        else
        {
          mvh = params.props.data.mvh;           
        }
      }
      if (colId === 'supplier_txt')
      {
         if (waarde && waarde.id === 0)
         {
            params.props.data.supplier_id =  waarde.id
            params.props.data.supplier_txt =  waarde.text
         }
      }
      if (colId === 'mvh')
      {
          bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(bedrag)) {bedrag = 0}
          totaal = bedrag + btw
          params.props.data.totaal = totaal;
      }    
      if (colId === 'btw')
      {
          bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(bedrag)) {bedrag = 0}
          totaal = bedrag + mvh
          params.props.data.totaal = totaal;
      }    
      if (colId === 'factnum')
      {
        params.props.data.factnum =  waarde
        if (params.state.losefocus === true && params.state.error === false)
        {
          let faktdata = await SqlFunction.DoSql('GetDubbelFactuur',params.props.data);
          if (faktdata && faktdata.data && faktdata.data.length > 0)
          {
            this.props.dispatch(zetview(this.state.data.description, this.state.data.id, 'JOB',this));
            this.setState({PopUpScreen:(<PopJobDubbelFactuur open={true} nivo={this.state.nivo + 1} mainid={this.state.data.id} data={faktdata.data} kol={faktdata.kol} close={this.ClosePopUp} title='Factuur reeds gebruikt bij' />)});
          }
        }
        else
        {

          terug = Validation.ValidCheck('AllJobOnkosten', waarde, colId, params, gridapi);
        }

      }
      if (params.props.node.rowPinned === 'top')
      {
        rowpinnend = params.props.data;
        rowpinnend.soort = 'ONKOSTEN' 
      }
      await params.setState({error:terug})
      await this.CheckSaveOnkost(params);

    }     

    async DfvLijstCheck(params,waarde) {
      var colId = params.props.column.getId();
      params.props.data.save = true;
      let terug = false
      let totaal = 0
      let bedrag = 0
      let btw = 0
      let mvh = 0
      if (params.props.data.btw) 
      {
        if (params.props.data.btw.constructor === String)
        {
          btw = parseFloat(params.props.data.btw);           
          if (isNaN(btw)) {btw = 0}
        }
        else
        {
          btw = params.props.data.btw;           
        }
      }
      if (params.props.data.mvh) 
      {
        if (params.props.data.mvh.constructor === String)
        {
          mvh = parseFloat(params.props.data.mvh);           
          if (isNaN(mvh)) {mvh = 0}
        }
        else
        {
          mvh = params.props.data.mvh;           
        }
      }
      if (colId === 'bedrag')
      {
          bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(bedrag)) {bedrag = 0}
//          params.props.data.totaal = totaal;
      }    
      await params.setState({error:terug})
//      await this.CheckSaveOnkost(params);

    }     


    async SubBoetesCheck(params,waarde) {
      var colId = params.props.column.getId();
      params.props.data.save = true;
      let terug = false
      let bedrag = 0
      let wbedrag = 0;
      if (params.props.data.bedrag) 
      {
        if (params.props.data.bedrag.constructor === String)
        {
          bedrag = parseFloat(params.props.data.bedrag);           
          if (isNaN(bedrag)) {bedrag = 0}
        }
        else
        {
          bedrag = params.props.data.bedrag;           
        }
      }
      if (colId === 'bedrag')
      {
          wbedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0}
      }    
      if (colId === 'datum')
      {
        params.props.data.datum = waarde; 
      }
      if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 1 )
      {
          params.props.data.save = true;
      }
      else
      {
        params.props.data.save = ''; 
      }
      if (params.props.node.rowPinned === 'top')
      {
        rowpinnend = params.props.data;
        rowpinnend.soort = 'BOETES' 
      }
      await params.setState({error:terug})
      await this.CheckSaveBoetes(params);
    }      

    async SubDouanesCheck(params,waarde) {
      var colId = params.props.column.getId();
      params.props.data.save = true;
      let terug = false
      let bedrag = 0
      let wbedrag = 0;
      if (params.props.data.bedrag) 
      {
        if (params.props.data.bedrag.constructor === String)
        {
          bedrag = parseFloat(params.props.data.bedrag);           
          if (isNaN(bedrag)) {bedrag = 0}
        }
        else
        {
          bedrag = params.props.data.bedrag;           
        }
      }
      if (colId === 'bedrag')
      {
          wbedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0}
      }    
      if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 1 )
      {
          params.props.data.save = true;
      }
      else
      {
        params.props.data.save = ''; 
      }
      if (params.props.node.rowPinned === 'top')
      {
        rowpinnend = params.props.data;
        rowpinnend.soort = 'DOUANES' 
      }
      await params.setState({error:terug})
      await this.CheckSaveDouanes(params);
    }       

    async GetOnkosten() {
        let retdata = await SqlFunction.DoSql('GetOpenOnkosten',this.state.data);
        this.setState({PopUpScreen:(<PopJobOpenOnkosten open={true} nivo={this.state.nivo + 1} omzetbel={this.state.data.omzetbel} mainid={this.state.data.id} data={retdata.data} kol={retdata.kol} close={this.ClosePopUpOnk} title='Open onkostenlijst' />)});
    }

    async GetOpenAang() {
        let retdata = await SqlFunction.DoSql('GetOpenContrAang',this.state.data);
        this.setState({PopUpScreen:(<PopJobContrAanv open={true} nivo={this.state.nivo + 1} omzetbel={this.state.data.omzetbel} mainid={this.state.data.id} data={retdata.data} kol={retdata.kol} close={this.ClosePopUpOnk} title='Lijst aangiftes' />)});
    }

    async SubOnkostenCellClick(waarde) {
      var data = waarde.data
      let column = waarde.column.colDef.field;
      let retdata = null;
      let jobdata = null;
      let rowonkost = null;
      var i = 0;
      if (column === 'save')
      {
        if (data.save === true) 
        {
          if (data.id === 0) {rowpinnend = null}
          data.job_id = this.state.data.id;
          data.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobOnkosten',data);
          waarde.data.save='';
          if (data.id === 0) 
          {
            waarde.data.id = retdata.id;

            gridapi.api.stopEditing();
            gridapi.api.updateRowData({
              add: [waarde.data],
              addIndex: 0
            });
            waarde.node.setData({id:0,job_id:0,supplier_id:'',type_onkost:'',country:'',factnum:'',factdat:'',mvh:'',btw:'',totaal:'',country_txt:'',supplier_txt:'',type_onkost_txt:'',save:'',verwijder:''});
          }
          else {
            waarde.node.setData(waarde.data);
          }
          if (rowpinnend && rowpinnend.save === true)
          {
            rowpinnend.job_id = this.state.data.id;
            rowpinnend.verwijder = '2';
            retdata = await SqlFunction.DoSql('ChgJobOnkosten',rowpinnend);
            rowpinnend = null;
          }
          for (i = 0; i < this.state.onkosten.length; i++) 
          {
            rowonkost = this.state.onkosten[i];
            if (rowonkost.save === true)
            {
              await SqlFunction.DoSql('ChgJobOnkosten',rowonkost);
            }
          }
          jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
          rowpinnend = null;
          await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol})          
          this.WorkFlowClickverder(0,true,true)
          this.WorkFlowClickverder(this.state.changewfindex,true)
        }
      }
      if (column === 'verwijder')
      {
        await SqlFunction.DoSql('DelJobOnkosten',data);
        gridapi.api.updateRowData({ remove: [waarde.data]})
        if (rowpinnend && rowpinnend.save === true)
        {
          rowpinnend.job_id = this.state.data.id;
          rowpinnend.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobOnkosten',rowpinnend);
          rowpinnend = null;
        }

        for (i = 0; i < this.state.onkosten.length; i++) 
        {
          rowonkost = this.state.onkosten[i];
          if (rowonkost.save === true)
          {
            await SqlFunction.DoSql('ChgJobOnkosten',rowonkost);
          }
        }

        jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
        rowpinnend = null;
        await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol})  
        this.WorkFlowClickverder(0,true,true)
        this.WorkFlowClickverder(this.state.changewfindex,true)
      }

      return;
    }

    async DfvLijstCellClick(waarde) {
      var data = waarde.data
      let column = waarde.column.colDef.field;
      let retdata = null;
      let jobdata = null;
      let rowonkost = null;
      var i = 0;
      if (column === 'save')
      {
        if (data.save === true) 
        {
          if (data.id === 0) {rowpinnend = null}
//          retdata = await SqlFunction.DoSql('ChgJobOnkosten',data);
          waarde.data.save='';
          waarde.node.setData(waarde.data);
//          jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
//          await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol})          
//          this.WorkFlowClickverder(0,true,true)
//          this.WorkFlowClickverder(this.state.changewfindex,true)
        }
      }
      return;
    }

    async SubBoetesCellClick(waarde) {
      var data = waarde.data
      let column = waarde.column.colDef.field;
      let retdata = null;
      let jobdata = null;
      let rowonkost = null;
      var i = 0;
      if (column === 'save')
      {
        if (data.save === true) 
        {
          if (data.id === 0) {rowpinnend = null}
          data.job_id = this.state.data.id;
          data.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobBoetes',data);
          waarde.data.save='';
          if (data.id === 0) 
          {
            waarde.data.id = retdata.id;

            gridapi.api.stopEditing();
            gridapi.api.updateRowData({
              add: [waarde.data],
              addIndex: 0
            });
            waarde.node.setData({id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''});
          }
          else {
            waarde.node.setData(waarde.data);
          }
          if (rowpinnend && rowpinnend.save === true)
          {
            rowpinnend.job_id = this.state.data.id;
            rowpinnend.verwijder = '2';
            retdata = await SqlFunction.DoSql('ChgJobBoetes',rowpinnend);
            rowpinnend = null;
          }
          for (i = 0; i < this.state.boetes.length; i++) 
          {
            rowonkost = this.state.boetes[i];
            if (rowonkost.save === true)
            {
              await SqlFunction.DoSql('ChgJobBoetes',rowonkost);
            }
          }
          jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
          rowpinnend = null;
          await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol})          
          this.WorkFlowClickverder(0,true,true)
          this.WorkFlowClickverder(this.state.changewfindex,true)
        }
      }
      if (column === 'verwijder')
      {
        await SqlFunction.DoSql('DelJobBoetes',data);
        gridapi.api.updateRowData({ remove: [waarde.data]})
        if (rowpinnend && rowpinnend.save === true)
        {
          rowpinnend.job_id = this.state.data.id;
          rowpinnend.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobBoetes',rowpinnend);
          rowpinnend = null;
        }

        for (i = 0; i < this.state.boetes.length; i++) 
        {
          rowonkost = this.state.boetes[i];
          if (rowonkost.save === true)
          {
            await SqlFunction.DoSql('ChgJobBoetes',rowonkost);
          }
        }

        jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
        rowpinnend = null;
        await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
            douaneskol:jobdata.douaneskol})          
        this.WorkFlowClickverder(0,true,true)
        this.WorkFlowClickverder(this.state.changewfindex,true)
      }

      return;
    }

    async SubDouanesCellClick(waarde) {
      var data = waarde.data
      let column = waarde.column.colDef.field;
      let retdata = null;
      let jobdata = null;
      let rowonkost = null;
      var i = 0;
      if (column === 'save')
      {
        if (data.save === true) 
        {
          if (data.id === 0) {rowpinnend = null}
          data.job_id = this.state.data.id;
          data.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobDouanes',data);
          waarde.data.save='';
          if (data.id === 0) 
          {
            waarde.data.id = retdata.id;

            gridapi.api.stopEditing();
            gridapi.api.updateRowData({
              add: [waarde.data],
              addIndex: 0
            });
            waarde.node.setData({id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''});
          }
          else {
            waarde.node.setData(waarde.data);
          }
          if (rowpinnend && rowpinnend.save === true)
          {
            rowpinnend.job_id = this.state.data.id;
            rowpinnend.verwijder = '2';
            retdata = await SqlFunction.DoSql('ChgJobDouanes',rowpinnend);
            rowpinnend = null;
          }
          for (i = 0; i < this.state.douanes.length; i++) 
          {
            rowonkost = this.state.douanes[i];
            if (rowonkost.save === true)
            {
              await SqlFunction.DoSql('ChgJobDouanes',rowonkost);
            }
          }
          jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
          rowpinnend = null;
          await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes, douaneskol:jobdata.douaneskol,linkcontraang: jobdata.linkcontraang,linkonkosten: jobdata.linkonkosten})          
          this.WorkFlowClickverder(this.state.changewfindex,true)
          this.WorkFlowClickverder(0,true,true)

        }
      }
      if (column === 'verwijder')
      {
        await SqlFunction.DoSql('DelJobDouanes',data);
        gridapi.api.updateRowData({ remove: [waarde.data]})
        if (rowpinnend && rowpinnend.save === true)
        {
          rowpinnend.job_id = this.state.data.id;
          rowpinnend.verwijder = '2';
          retdata = await SqlFunction.DoSql('ChgJobDouanes',rowpinnend);
          rowpinnend = null;
        }

        for (i = 0; i < this.state.douanes.length; i++) 
        {
          rowonkost = this.state.douanes[i];
          if (rowonkost.save === true)
          {
            await SqlFunction.DoSql('ChgJobDouanes',rowonkost);
          }
        }

        jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
        rowpinnend = null;
        await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol})          
        this.WorkFlowClickverder(this.state.changewfindex,true)
        this.WorkFlowClickverder(0,true,true)
      }

      return;
    }

    handleLinkPdf(data,type)
    {
      let verder = false;
      let bewdata = {data:this.state.data, workflows:this.state.workflows}
      SqlFunction.DoSql('ChgJob',bewdata);

      if (type) {this.setState({vatjob_type:type})} else {return}
      if (!data.completed || data.completed === false || data.completed === '0')
      {
        if (this.state.data && this.state.data.user_id === this.props.user.userData.id)
        {
          verder = true;
        }
        else
        {
          if (this.state.data && GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit'))
          {
            verder = true;
          }        
        }
      }
      else
      {
        if (this.state.data && (this.state.data.user_id === this.props.user.userData.id && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit')))
        {
          verder = true;
        }
        else
        {
          if (this.state.data && ( GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit') && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit') ) )
          {
            verder = true;
          }        
        }
      }
      if (verder === true)
      {
        dropworkflow = data;
        dropdoc = null;
        let element = document.getElementById(jobdropid);
        element.click()
      }
    }

    AddDocument(data)
    {
      let verder = false;
      if (!data.completed || data.completed === false || data.completed === '0')
      {
        if (this.state.data && this.state.data.user_id === this.props.user.userData.id)
        {
          verder = true;
        }
        else
        {
          if (this.state.data && GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit'))
          {
            verder = true;
          }        
        }
      }
      else
      {
        if (this.state.data && (this.state.data.user_id === this.props.user.userData.id && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit')))
        {
          verder = true;
        }
        else
        {
          if (this.state.data && ( GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit') && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit') ) )
          {
            verder = true;
          }        
        }
      }
      if (verder === true)
      {
        dropworkflow = data;
        dropdoc = null;
        let element = document.getElementById(jobdropid);
        element.click()
      }
    }

    async ClickDocument(data)
    {
      await this.setState({DownloadData:data});
      document.getElementById(jobformid).submit();
    }

    async DeleteDocument(docdata, data)
    {
      let verder = false;
      if (!data.completed || data.completed === false || data.completed === '0')
      {
        if (this.state.data && this.state.data.user_id === this.props.user.userData.id)
        {
          verder = true;
        }
        else
        {
          if (this.state.data && GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit'))
          {
            verder = true;
          }        
        }
      }
      else
      {
        if (this.state.data && (this.state.data.user_id === this.props.user.userData.id && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit')))
        {
          verder = true;
        }
        else
        {
          if (this.state.data && ( GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit') && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit') ) )
          {
            verder = true;
          }        
        }
      }
      if (verder === true)
      {
        await SqlFunction.DoSql('DeleteFile',docdata);
        let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
        rowpinnend = null;
        this.setState({data:jobdata.data,workflows:jobdata.workflows})
        this.getStories()
      }
      return;

    }

    async DeleteLinkJob(data)
    {
      let hdata = {data:this.state.data, linkdata:data}
      await SqlFunction.DoSql('DeleteLinkJob',hdata);
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol,save:false})       
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async DeleteContrAanvr(data)
    {
      let hdata = {data:this.state.data, linkdata:data}
      await SqlFunction.DoSql('DeleteContrAanvr',hdata);
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol,save:false})       
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async onDrop(files) {
      if (dropworkflow && (dropworkflow.bestanden === '1' || dropworkflow.bestanden === true))
      {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('file', file);
        }

        if (dropdoc)
        {
          formData.set('accountid', dropdoc.account_id);
          formData.set('reg_id', dropdoc.reg_id);
          formData.set('kant_id', dropdoc.kant_id);

          formData.set('type', dropdoc.type);    
          formData.set('docid', dropdoc.id);    
          formData.set('docdata', dropdoc);    
          if (this.state.vatjob_type)
          {
            formData.set('vatjob_type', this.state.vatjob_type);    
          }
        }
        else
        {
          formData.set('accountid', this.state.data.account_id);
          formData.set('reg_id', this.state.data.registration_id);
          formData.set('kant_id', this.state.data.kantoor_id);
          formData.set('job_id', this.state.data.id);
          formData.set('workflow_id', dropworkflow.id);
          formData.set('type', 'workflow');    
          if (this.state.vatjob_type)
          {
            formData.set('vatjob_type', this.state.vatjob_type);    
          }

        }
        let verder = false;
        if (!dropworkflow.completed || dropworkflow.completed === false || dropworkflow.completed === '0')
        {
          if (this.state.data && this.state.data.user_id === this.props.user.userData.id)
          {
            verder = true;
          }
          else
          {
            if (this.state.data && GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit'))
            {
              verder = true;
            }        
          }
        }
        else
        {
          if (this.state.data && (this.state.data.user_id === this.props.user.userData.id && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit')))
          {
            verder = true;
          }
          else
          {
            if (this.state.data && ( GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit') && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit') ) )
            {
              verder = true;
            }        
          }
        }
        if (verder === true)
        {
          await SqlFunction.DoSqlBody('saveFile',formData);
          dropdoc = null;
          let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
          rowpinnend = null;
          this.setState({data:jobdata.data,workflows:jobdata.workflows,vatjob_type:null})
          this.getStories()
        }
      }
    };

    SetObject(object,colid)
    {
        switch (colid) 
        {
          case 'registratie_txt':
            regisobj = object;
            break;

          case 'refertenum':
            refertenumobj = object;
            break;


          case 'corr_aang_txt':
            corraangobj = object;
            break;

          case 'corr_controle_txt':
            corrcontroleobj = object;
            break;


          case 'account_name':
            accountobj = object;
            break;

          case 'kantoor_txt':
            kantoorobj = object;
            break;

          case 'country_txt':
            countryobj = object;
            break;

          case 'user_name':
            userobj = object;
            break;

          default:    
            break;
        } 
    }

    async handleAfhandel(data)
    {
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      let wardata = this.state.workflows; 
      let sdata = this.state.data; 
      let wfdata = wardata[data.origindex];
      wfdata.userid = this.props.user.userData.id;
      wfdata.datum = GenFunc.Get_Vandaag();
      wfdata.completed = '1';
      wardata[data.index] = wfdata;
      let overzdata = {data:this.state.data, workflows:wardata}
      await SqlFunction.DoSql('ChgJob',overzdata);
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      rowpinnend = null;
      sdata = jobdata.data;
      wardata = jobdata.workflows;
      if (sdata.crea_aang === '1')
      {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Job(s) Aangifte maken voor registratie ?</h1>
                    <button
                      onClick={() => {
                        this.handleJobRegJa();
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            
      }

      if (sdata.refertenum && sdata.refertenum.length > 2 && sdata.crea_aang !== '9' && sdata.code.includes('VOI')  === false)
      {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Job aangifte volgende periode maken ?</h1>
                    <button
                      onClick={() => {
                        this.handleJobVatJa();
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            
      }
      this.setState({data:jobdata.data,workflows:jobdata.workflows})
      this.getStories()


    }

    async handleJobRegJa() {
      if (this.state.data) 
      {
        await SqlFunction.DoSql('CreaJobAanReg',this.state.data);
      }
    }

    async handleJobVatJa() {
      if (this.state.data) 
      {
        await SqlFunction.DoSql('CreaJobAanVat',this.state.data);
      }
    }


    async CreaVoi() {
      if (this.state.data) 
      {
        let retdata = await SqlFunction.DoSql('CreaVoi',this.state.data);
        this.GoToLinkJob(retdata.id)

      }
    }




    WorkFlowEnter(event,data)
    {
      dropworkflow = data;
    }

    async WorkFlowClick(index)
    {
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      if (this.props.data && this.props.data.data && this.props.data.data.fact_id && this.props.data.data.fact_id.length > 4)
      {
//          return;
      }

       // Popup met vorige niet verwerkte aangiftes
       await SqlFunction.DoSql('GetVaangifte',this.state.data);

       // einde popup met vorige niet verwerkte aangiftes
        workflowstorie = this.state.workflowstories[index];
        if (!workflowstorie.completed || workflowstorie.completed === false || workflowstorie.completed === '0')
        {
          if (this.state.data && this.state.data.user_id === this.props.user.userData.id)
          {
            this.WorkFlowClickverder(index);
          }
          else
          {
            if (this.state.data && GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit'))
            {
              this.WorkFlowClickverder(index);
            }        

          }
        }
        else
        {
          if (this.state.data && (this.state.data.user_id === this.props.user.userData.id && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit')))
          {
            this.WorkFlowClickverder(index);
          }
          else
          {
            if (this.state.data && ( GenFunc.allowed(this.props.user.userData.secrows,'JobDifUser','edit') && GenFunc.allowed(this.props.user.userData.secrows,'JobCompWorkflow','edit') ) )
            {
              this.WorkFlowClickverder(index);
            }        

          }

        }
    }

    async WorkFlowClickverder(index,always,noedit)
    {
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      if (this.props.data && this.props.data.data && this.props.data.data.fact_id && this.props.data.data.fact_id.length > 4)
      {
//          return;
      }
      
      workflowstorie = this.state.workflowstories[index];
      let edit = true;
      let result = (workflowstorie && workflowstorie.velden) ? workflowstorie.velden.filter(o=> o.veldcode === 'tab_boetes') : null;
      if (Array.isArray(result) && !noedit)
      {
        if (result.length > 0)
        {
          this.setState({changewfindex:index})
          this.TableBoeteEdit();
          return;
        }
      }

      result = (workflowstorie && workflowstorie.velden) ? workflowstorie.velden.filter(o=> o.veldcode === 'tab_douane') : null;
      if (Array.isArray(result) && !noedit)
      {
        if (result.length > 0)
        {
          this.setState({changewfindex:index})
          this.TableDouaneEdit();
          return;
        }
      }

      result = (workflowstorie && workflowstorie.velden) ? workflowstorie.velden.filter(o=> o.veldcode === 'tab_onkosten') : null;
      if (Array.isArray(result) && !noedit)
      {
        if (result.length > 0)
        {
          this.setState({changewfindex:index})
          this.TableOnkostEdit();
          return;
        }
      }

      result = (workflowstorie && workflowstorie.velden) ? workflowstorie.velden.filter(o=> o.veldcode === 'tab_vatref') : null;
      if (Array.isArray(result) && !noedit)
      {
        if (result.length > 0)
        {
          this.setState({changewfindex:index})
          this.TableVatRefundOnkostEdit(workflowstorie);
          return;
        }
      }


      

      if (noedit === true) {edit = false}
      if (workflowstorie && (this.state.changewfindex === null || always === true))
      {
        workflowstorie.body = ( <Grid container spacing={1}>
                                    {workflowstorie.velden.map(datavld => {
                                      return this.getFields(datavld.veldcode,datavld.description,datavld.verplicht,edit)
                                    })}                                  
                                </Grid>)
        if (!noedit)
        {
          workflowstorie.footer = (
                    <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                      <Button  disabled={save} style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleSave()}>
                       Bewaren
                      </Button>
                      <Button style={{background:'#8898aa',marginLeft:'10px',color:'#fff'}} onClick={() => this.handleFlowCancel(index)}>
                       Annuleren
                      </Button>

                    </div>

                  )
        }
        let wfstories = this.state.workflowstories;
        wfstories[index] = workflowstorie;
        if (always === true)
        {
          this.setState({workflowstories:wfstories})
        }
        else
        {
          this.setState({workflowstories:wfstories, changewfindex:index})
        }
      }
    }

    async handleFlowCancel(index) 
    {
      if (this.state.data && this.state.data.id === 0) 
      {
        if (this.props.close) {
            this.props.close()
        }
      }
      else
      {
        let zoekdata = this.state.data ? this.state.data : this.props.data.data;
        let jobdata = await SqlFunction.DoSql('GetJob',zoekdata);
        rowpinnend = null;
        await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol,save:false})          

        this.getStories()
      }
    }

    getFields(fieldcode,description,verplicht,edit){
      let fielddata = this.state.data ? this.state.data : this.props.data.data ? this.props.data.data : null;
      let veld = null;
      let factcheck = false;
      let errstijl = {color:'red !important'}; 
      let errclass = "text-muted p-t-30 db";   
      let numval = 0
      let totvatmvhvalue = 0;
      let totvatmvhvalue_val = 0;      
      let muntvalue = 0;
      if (this.state.data)
      {
        fielddata = this.state.data;
      }
      if (edit === false)
      {
        if (verplicht === '1' || verplicht === true || verplicht === 1)
        {
          if (fielddata[fieldcode] !== undefined && fielddata[fieldcode] !== null)
          {
            if(fielddata[fieldcode].length < 2) 
            {
              errstijl = {color:'red !important'};            
              errclass = "text-muted-error p-t-30 db";   
            }
          }
          else
          {
            errstijl = {color:'red !important'};            
            errclass = "text-muted-error p-t-30 db";   
          }
        }

        switch (fieldcode) 
        {
          case 'aang_optie':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel style={errstijl} component="legend">Aangifteopties</FormLabel>
                            <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="aangifte" name="aangifte" value={fielddata.aang_optie} >
                              <FormControlLabel disabled value="1" labelPlacement="start" control={<Radio color="primary" />} label="Aangifte met saldo" />
                              <FormControlLabel disabled value="2" labelPlacement="start" control={<Radio color="primary" />} label="Nihil met ritten" />
                              <FormControlLabel disabled value="3" labelPlacement="start" control={<Radio color="primary" />} label="Nihil zonder ritten" />
                            </RadioGroup>                            
                           </FormControl>
                      </Grid>)
            break;


          case 'tot_boetes':
              totvatmvhvalue = parseFloat(fielddata.tot_boetes)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_boetes_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;



          case 'tot_douane':
              totvatmvhvalue = parseFloat(fielddata.tot_douane)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_douane_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;

          case 'algtot_onkosten':
              totvatmvhvalue = parseFloat(fielddata.algtot_onkosten)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.algtot_onkosten_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;


          case 'tot_onkosten':
              totvatmvhvalue = parseFloat(fielddata.tot_onkosten)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_onkosten_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;







          case 'terug_optie':
            if (fielddata.terug_optie === '1' || fielddata.terug_optie === 1)
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <h6>Teruggave 100% geaccepteerd</h6>
                      </Grid></Grid>)

            }
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <h6>Teruggave niet 100% geaccepteerd</h6>
                      </Grid></Grid>)
              
            }
            break;


          case 'terug_aanv':
              let terug_aanv = (fielddata.terug_aanv === '1' || fielddata.terug_aanv === true) ? true : false;
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div>
                          <small className="text-muted p-t-30 db">100% aanvaard: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                  control={<Checkbox checked={terug_aanv} />} />
                          </small>              
                      </div>
                      </Grid>)
            break;                     

          case 'afronden':
              let afronden = (fielddata.afronden === '1' || fielddata.afronden === true) ? true : false;
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div>
                          <small className="text-muted p-t-30 db">Afronden: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                  control={<Checkbox checked={afronden} />} />
                          </small>              
                      </div>
                      </Grid>)
            break;                     

          case 'terug_actie':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '4' || fielddata.terug_actie === 4)
              {            
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <small style={errstijl} className={errclass}>{description}</small>
                            <h6>{fielddata.terug_actie_txt}</h6>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            {fielddata.correctie && fielddata.correctie.length > 4 &&
                              <div style={{textAlign:'center', width:'100%', display: 'inline-block',cursor:'pointer'}}>
                                <small style={errstijl} className={errclass}>Correctie Job</small>
                                <h6 onClick={() => this.GoToLinkJob(fielddata.correctie)} >{fielddata.correctie_txt}</h6>
                              </div>
                            }
                          </Grid>
                      </Grid>)                
              }
              else
              {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <small style={errstijl} className={errclass}>{description}</small>
                        <h6>{fielddata.terug_actie_txt}</h6>
                      </Grid></Grid>)
              }
            }
            break;


          case 'terug_remarks':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <small style={errstijl} className={errclass}>{description}</small>
                        <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'  
                            multiline={true}                      
                            disabled
                            readonly = {true}
                         />                                                
                      </Grid></Grid>)
            }
            break;

          case 'vat_factopm':
          case 'info1':
          case 'info2':
          case 'info_tab1':
          case 'info_tab2':
          case 'info_tab3':
          case 'info_tab4':
          case 'info_tab5':
          case 'info_tab6':
          case 'info_tab7':
          case 'info_tab8':
          case 'info_tab9':
          case 'info_tab10':
          case 'info_tab11':
          case 'info_tab12':
          case 'info_tab13':
          case 'info_tab14':
          case 'info_tab15':
          case 'info_tab16':
          case 'info_tab17':
          case 'info_tab18':
          case 'info_tab19':
          case 'info_tab20':


                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <small style={errstijl} className={errclass}>{description}</small>
                        <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'  
                            multiline={true}                      
                            disabled
                            readonly = {true}
                         />                                                
                      </Grid></Grid>)
            break;

 
          case 'dat_bezwaar':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
 
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <small style={errstijl} className={errclass}>{description}</small>
                            <h6>{fielddata[fieldcode]}</h6>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', width:'100%', display: 'inline-block',cursor:'pointer'}}>
                              <small style={errstijl} className={errclass}>Gekoppeld document</small>
                              <h6 onClick={() => this.ClickDocument({id:fielddata.pdf_bezwaar})} >{fielddata.pdf_bezwaar_txt}</h6>
                            </div>
                          </Grid>
                      </Grid>)
              }
            }
            break;

          case 'respons_bezwaar':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
 
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <small style={errstijl} className={errclass}>{description}</small>
                            <h6>{fielddata[fieldcode]}</h6>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', width:'100%', display: 'inline-block',cursor:'pointer'}}>
                              <small style={errstijl} className={errclass}>Gekoppeld document</small>
                              <h6 onClick={() => this.ClickDocument({id:fielddata.pdf_respons})} >{fielddata.pdf_respons_txt}</h6>
                            </div>
                          </Grid>
                      </Grid>)
              }
            }
            break;

          case 'terug_date':
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <small style={errstijl} className={errclass}>{description}</small>
                            <h6>{fielddata[fieldcode]}</h6>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                          </Grid>
                      </Grid>)
            break;



          case 'def_beslis_aanv':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
//                let def_beslis = (fielddata.def_beslis_aanv === '1' || fielddata.def_beslis_aanv === true) ? true : false;
                value = parseFloat(fielddata.def_beslis_bedrag)
                if (isNaN(value)) {value = 0}
                if (typeof value === 'number') 
                {
                  value = value.toFixed(2)
                }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid style={{textAlign: 'left',paddingRight:'20px'}} item sm={12} md={12} lg={12}>
                         <h6 style={{fontWeight:'bold', marginTop: '20px'}}>Definitieve beslissing: </h6>
                      </Grid>

                      <Grid item sm={6} md={6} lg={6}>
                        <div>
                          {(fielddata.def_beslis_aanv === '1' || fielddata.def_beslis_aanv === 1) &&
                            <h6>Bezwaar 100% aanvaard</h6>
                          }
                          {(fielddata.def_beslis_aanv === '0' || fielddata.def_beslis_aanv === 0) &&
                            <h6>Bezwaar niet 100% aanvaard</h6>
                          }

                        </div>                      
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <div>
                          <small className="text-muted p-t-30 db">Def beslissing bedrag </small>              
                          <h6>{value.toLocaleString()}</h6>
                        </div>                      
                      </Grid>
                    </Grid>)
              }
            }
            break;
          case 'tot_vat_btw':
          case 'tot_vat_doc':

              veld = null;
              break;

          case 'tot_vat_mvh':
              totvatmvhvalue = parseFloat(fielddata.tot_vat_mvh)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              let totvatbtwvalue = parseFloat(fielddata.tot_vat_btw)
              if (isNaN(totvatbtwvalue)) {totvatbtwvalue = 0}
              let totvatdoc = parseFloat(fielddata.tot_vat_doc)
              if (isNaN(totvatdoc)) {totvatdoc = 0}

              totvatmvhvalue_val = parseFloat(fielddata.tot_vat_mvh_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              let totvatbtwvalue_val = parseFloat(fielddata.tot_vat_btw_val)
              if (isNaN(totvatbtwvalue_val)) {totvatbtwvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }
              if (typeof totvatbtwvalue_val === 'number') 
              {
                totvatbtwvalue_val = totvatbtwvalue_val.toFixed(2)
              }

              

                veld =  (
                  <div key={'KJA'+fieldcode+this.state.refid} style ={{width:'100%'}}>
                    <Grid container spacing={1}>
                      <Grid item sm={4} md={4} lg={4}>
                        <div>
                          <small className="text-muted p-t-30 db">Totaal MVH </small>              
                          <h6>{totvatmvhvalue_val && typeof(totvatmvhvalue_val) === 'number' ? 
                               new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: fielddata.munt }).format(totvatmvhvalue_val.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: fielddata.munt }).format(parseFloat(totvatmvhvalue_val).toFixed(2))}</h6>
                        </div>                      
                      </Grid>
                      <Grid item sm={4} md={4} lg={4}>
                        <div>
                          <small className="text-muted p-t-30 db">Totaal BTW </small>              
                          <h6>{totvatbtwvalue_val && typeof(totvatbtwvalue_val) === 'number' ? 
                               new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: fielddata.munt }).format(totvatbtwvalue_val.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: fielddata.munt }).format(parseFloat(totvatbtwvalue_val).toFixed(2))}</h6>
                        </div>                      
                      </Grid>
                      <Grid item sm={4} md={4} lg={4}>
                        <div>
                          <small className="text-muted p-t-30 db">Aantal Facturen </small>              
                          <h6>{totvatdoc.toLocaleString()}</h6>
                        </div>                      
                      </Grid>
                    </Grid>
                    {fielddata.munt !== 'EUR' &&
                    <Grid container spacing={1}>
                      <Grid item sm={4} md={4} lg={4}>
                        <div>
                          <small className="text-muted p-t-30 db">Totaal MVH </small>              
                          <h6>{totvatmvhvalue && typeof(totvatmvhvalue) === 'number' ? 
                               new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: 'EUR' }).format(totvatmvhvalue.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: 'EUR' }).format(parseFloat(totvatmvhvalue).toFixed(2))}</h6>
                        </div>                      
                      </Grid>
                      <Grid item sm={4} md={4} lg={4}>
                        <div>
                          <small className="text-muted p-t-30 db">Totaal BTW </small>              
                          <h6>{totvatbtwvalue && typeof(totvatbtwvalue) === 'number' ? 
                               new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: 'EUR' }).format(totvatbtwvalue.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ style: 'currency', currency: 'EUR' }).format(parseFloat(totvatbtwvalue).toFixed(2))}</h6>
                        </div>                      
                      </Grid>
                    </Grid>}
                  </div>)
            break;



          case 'dfv_import':
          case 'jvl_import':
          case 'xml_vataang':
          case 'def_beslis_bedrag':
          case 'dfv_creadoc':
          case 'jvl_creadoc':
          case 'pdf_bezwaar':
          case 'pdf_respons':
          case 'terug_date_pdf':


            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      </Grid>)
            break;



          case 'dok':
            if (fielddata.dok && fielddata.dok.length > 5)
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{width:'100%', textAlign:'left', overflowY:'auto', display:'inline-block',height:'73vh',cursor:'pointer'}} 
                            dangerouslySetInnerHTML={{ __html: fielddata.dok}}>
                        </div>
                      </Grid>)
            }
            else
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      </Grid>)

            }
            break;


          case 'make_onkosten_pdf_listing':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleOnkListing()}>
                             Maken lijst onkosten
                          </Button>
                        </div>
                      </Grid>)
                break



          case 'contr_afhand':
            veld =  ( <div key={'KJA'+fieldcode+this.state.refid} style={{width:'100%'}}>
                        <Grid container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Afhandeling</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="afhandeling" name="afhandeling" value={fielddata.contr_afhand} >
                                  <FormControlLabel disabled value="1" labelPlacement="start" control={<Radio color="primary" />} label="Zonder gevolg" />
                                  <FormControlLabel disabled value="2" labelPlacement="start" control={<Radio color="primary" />} label="Bijkomende aangifte" />
                                </RadioGroup>                            
                            </FormControl>
                          </Grid>
                          {fielddata.contr_afhand === '2' &&
                            <Grid style={{paddingTop:'30px'}}item sm={6} md={6} lg={6}>
                                {fielddata.contr_bijk_aang.length > 4 &&
                                  <h6 style={{paddingTop:'10px',cursor:'pointer'}} onClick={() => this.GoToLinkJob(fielddata.contr_bijk_aang)}>{fielddata.contr_bijk_aang_txt}</h6>
                                }                          
                            </Grid>
                          }
                        </Grid>
                      </div>)
            break;

          case 'contr_inhoud':

            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.contr_afhand)}>{fielddata[fieldcode]}</h6>
                      </Grid></Grid>)
              break;

          case 'contr_periode':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={4} md={4} lg={4}>
                          <small className={errclass}>Periode</small>
                        </Grid>
                        <Grid item sm={8} md={8} lg={8}>
                          <small className={errclass}>Startdatum</small>
                          <h6>{fielddata.contr_start_date}</h6>
                        </Grid>
                        <Grid item sm={4} md={4} lg={4}>
                        </Grid>
                        <Grid item sm={8} md={8} lg={8}>
                          <small className={errclass}>Einddatum</small>
                          <h6>{fielddata.contr_end_date}</h6>
                        </Grid>

                      </Grid>)
              break;

          case 'contr_tabaang':
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <small className={errclass}>Betreft aangifte(s)</small>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            {this.state.linkcontraang.map(onkdata => {
                              return (
                                <Chip
                                  style={{marginTop:'5px',marginRight:'5px'}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  key={GenFunc.MakeId()}
                                  label={onkdata.description}
                                  onClick={() => this.GoToLinkJob(onkdata.id)}
                                />
                              );
                            })}                                  
                        </Grid>
                      </Grid>)
            break;

          case 'kz_nihil':

            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {

                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Keuzemogelijkhied</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kznihil" name="kznihil" value={fielddata.kz_nihil} >
                                  <FormControlLabel disabled value="1" labelPlacement="start" control={<Radio color="primary" />} label="Periodieke aangifte" />
                                  <FormControlLabel disabled value="2" labelPlacement="start" control={<Radio color="primary" />} label="Vastgestelde DFV door DEU" />
                                </RadioGroup>                            
                               </FormControl>
                          </Grid>)
            }
            else
            {
              if (fielddata.aang_optie === '2')
              {          
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Keuzemogelijkhied</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kznihil" name="kznihil" value={fielddata.kz_nihil} >
                                  <FormControlLabel disabled value="1" labelPlacement="start" control={<Radio color="primary" />} label="Ritten aangegeven in een andere aangifte" />
                                  <FormControlLabel disabled value="2" labelPlacement="start" control={<Radio color="primary" />} label="Nihil aangifte" />
                                </RadioGroup>                            
                               </FormControl>
                          </Grid>)
              }
            }
            break;

          case 'corr_door':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel component="legend">Ingediend door</FormLabel>
                            <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kzdoor" name="kzdoor" value={fielddata.corr_door}  >
                              <FormControlLabel disabled value="1" labelPlacement="start" control={<Radio color="primary" />} label="Buitenlands BTW Kantoor" />
                              <FormControlLabel disabled value="2" labelPlacement="start" control={<Radio color="primary" />} label="Brutax" />
                            </RadioGroup>                            
                           </FormControl>
                      </Grid>)
            break;



          case 'corr_tabel':

              let omzetbel = parseFloat(fielddata.omzetbel)
              let voorbel = parseFloat(fielddata.voorbel)
              let saldotebet = parseFloat(fielddata.saldotebet)

              let corr_omzbel = parseFloat(fielddata.corr_omzbel)
              let corr_verwijl = parseFloat(fielddata.corr_verwijl)
              let corr_voorbel = parseFloat(fielddata.corr_voorbel)
              let corr_tebet = parseFloat(fielddata.corr_tebet)

              if (isNaN(omzetbel)) {omzetbel = 0}
              if (isNaN(voorbel)) {voorbel = 0}
              if (isNaN(saldotebet)) {saldotebet = 0}
              if (isNaN(corr_omzbel)) {corr_omzbel = 0}
              if (isNaN(corr_verwijl)) {corr_verwijl = 0}
              if (isNaN(corr_voorbel)) {corr_voorbel = 0}
              if (isNaN(corr_tebet)) {corr_tebet = 0}

              let gecorr_omzetbel = corr_omzbel - omzetbel
              let gecorr_voorbel = corr_voorbel - voorbel
              let corr_tebetaal = corr_omzbel - corr_voorbel
              let gecorr_tebet = corr_tebetaal - saldotebet                       

              veld =  (<div key={'KJA'+fieldcode+this.state.refid} style={{width:'100%'}}>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Initiële</h6>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Correctie</h6>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Gecorrigeerde</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Aangifte</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Aangifte</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Omzetbelasting :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{omzetbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{corr_omzbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_omzetbel.toLocaleString()}</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Voorbelasting :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{voorbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{corr_voorbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_voorbel.toLocaleString()}</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Saldo te betalen :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{saldotebet.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{corr_tebetaal.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_tebet.toLocaleString()}</h6>
                            </Grid>
                          </Grid>
                          <div></div>
                        <Grid  style={{paddingTop:'30px'}} container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Bedrag te betalen :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'right'}} item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{corr_tebet.toLocaleString()}</h6>
                            </Grid>
                          </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Datum Betaling :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{fielddata.corr_dat_bet}</h6>
                            </Grid>
                          </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Verwijlintrest :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'right'}} item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{fielddata.corr_verwijl}</h6>
                            </Grid>
                          </Grid>


                        </div>)

            break;
          case 'saldotebet':
            if (fielddata.aang_optie === '1' )
            {
              let value = parseFloat(fielddata[fieldcode])
              muntvalue = parseFloat(fielddata.saldotebet_val);
              if (isNaN(muntvalue)) {muntvalue = 0}
              if (typeof muntvalue === 'number') 
              {
                muntvalue = muntvalue.toFixed(2)
              }

              if (isNaN(value)) 
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }

                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">{description}</small>              
                            <h6>0 EUR</h6>
                          </Grid>
                        </Grid>)

              }
              else
              {
                if (value > 0)
                {

                  veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'red'}} item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small style={{color:'red'}} className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'red'}} item sm={6} md={6} lg={6}>
                            <small  style={{color:'red'}} className="text-muted p-t-30 db">{description}</small>              
                            <h6>{value.toLocaleString()} EUR</h6>
                          </Grid>
                        </Grid>)                

                }
                else
                {
                  value = value * -1;
                  muntvalue = muntvalue * -1;
                  veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'green'}} item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small  style={{color:'green'}} className="text-muted p-t-30 db">Saldo in voordeel :</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'green'}} item sm={6} md={6} lg={6}>
                            <small  style={{color:'green'}} className="text-muted p-t-30 db">Saldo in voordeel :</small>              
                            <h6>{value.toLocaleString()} EUR</h6>
                          </Grid>
                        </Grid>)                
  
                }
              }
            }
            break;          


          case 'crea_inlichting':
            if (fielddata.link_jobtxt && fielddata.link_jobtxt.length > 4)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                          <small className={errclass}>Link job vraag voor inlichtingen</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.link_jobid)}>{fielddata['link_jobtxt']}</h6>
                    </Grid>)
            }
            break;

          case 'link_jobtxt':

            if (fielddata.link_jobid && fielddata.link_jobid.length > 4)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.link_jobid)}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            }
            break;


          case 'corr_aang_txt':
            if (fielddata.corr_aang)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        {fielddata.corr_aang.length > 4 &&
                          <h6 onClick={() => this.GoToLinkJob(fielddata.corr_aang)}>{fielddata[fieldcode]}</h6>
                        }
                    </Grid>)
            }
            break;

          case 'corr_controle_txt':
            if (fielddata.corr_controle)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                          <small className={errclass}>{description}</small>
                            {fielddata.corr_controle.length > 4 &&
                              <h6 onClick={() => this.GoToLinkJob(fielddata.corr_controle)}>{fielddata[fieldcode]}</h6>
                            }
                    </Grid>)
            }
            break;

          case 'contr_id_txt':
            if (fielddata.contr_id && fielddata.contr_id.length > 4)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.contr_id)}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            }
            break;

          case 'corr_id_txt':
            if (fielddata.corr_id && fielddata.corr_id.length > 4)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.corr_id)}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            }
            break;

          case 'omzetbel':
          case 'dir_mvh':
          case 'voorafbetaling':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <div>
                             <h6>DFV</h6>
                          </div>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <h6>{fielddata.omzetbel.toLocaleString()} </h6>
                      </Grid>
                    </Grid>)

            }          


            if (fielddata.aang_optie === '1' )
            {
              let value = parseFloat(fielddata[fieldcode])
              muntvalue = 0;
              if (fieldcode === 'omzetbel')
              {
                 muntvalue = parseFloat(fielddata.omzetbel_val)
              }
              if (fieldcode === 'dir_mvh')
              {
                 muntvalue = parseFloat(fielddata.dir_mvh_val)
              }

              if (fieldcode === 'voorafbetaling')
              {
                 muntvalue = parseFloat(fielddata.voorafbetaling_val)
              }


              if (isNaN(muntvalue)) {muntvalue = 0}
              if (typeof muntvalue === 'number') 
              {
                muntvalue = muntvalue.toFixed(2)
              }

              if (isNaN(value)) 
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }

                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">{description}</small>              
                            <h6>0 EUR</h6>
                          </Grid>
                        </Grid>)
              }
              else
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">{description}</small>              
                            <h6>{value.toLocaleString()} EUR</h6>
                          </Grid>
                        </Grid>)                
              }
            }
            break;

          case 'tab_linkonkosten':
            if (fielddata.aang_optie === '1')
            {
              totvatmvhvalue = parseFloat(fielddata.voorbel)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.voorbel_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
            if (fielddata.job_id ===  'ec01cb3c87e4b5ce2fe28308fd9f2a7baf3' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">Gekoppelde onkosten</small>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            {this.state.linkonkosten.map(onkdata => {
                              return (
                                <Chip
                                  style={{marginTop:'5px',marginRight:'5px'}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  key={GenFunc.MakeId()}
                                  label={onkdata.description}
                                  onClick={() => this.GoToLinkJob(onkdata.id)}
                                />
                              );
                            })}                                  
                        </Grid>
                      </Grid>)
            }
            break;


          case 'voorbel':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
               veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                        <div>
                          <h6>Steuer</h6>
                        </div>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <h6>{fielddata.voorbel.toLocaleString()}</h6>
                      </Grid>
                    </Grid>)                      
            }
            if (fielddata.aang_optie === '1')
            {
              totvatmvhvalue = parseFloat(fielddata.voorbel)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.voorbel_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
            break;


          case 'tot_link_boetes':
            if (fielddata.aang_optie === '1' )
            {
              totvatmvhvalue = parseFloat(fielddata.tot_link_boetes)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_link_boetes_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
              break;


          case 'tot_link_douane':
            if (fielddata.aang_optie === '1' )
            {
              totvatmvhvalue = parseFloat(fielddata.tot_link_douane)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_link_douane_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
              break;


          case 'verwijlintrest':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              let value = parseFloat(fielddata[fieldcode])
              if (isNaN(value)) 
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                            <Grid item sm={6} md={6} lg={6}>
                              <small className={errclass}>{description}</small>
                              <h6>0</h6>
                            </Grid>
                        </Grid>)
              }
              else
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className={errclass}>{description}</small>
                            <h6>{value.toLocaleString()}</h6>
                          </Grid>
                        </Grid>)                
              }
            }

            if (fielddata.aang_optie === '1' )
            {
              let value = parseFloat(fielddata[fieldcode])
              if (isNaN(value)) 
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                            <Grid item sm={6} md={6} lg={6}>
                              <small className={errclass}>{description}</small>
                              <h6>0</h6>
                            </Grid>
                        </Grid>)
              }
              else
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className={errclass}>{description}</small>
                            <h6>{value.toLocaleString()}</h6>
                          </Grid>
                        </Grid>)                
              }
            }
            break;

          case 'dfv_periodes':
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                            <h6><div style={{paddingLeft:'20px'}} >{fielddata['sub_dfv_af']}</div></h6>
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                            <h6>{description} :  <div style={{paddingLeft:'20px'}} >{fielddata[fieldcode]}</div></h6>
                          </Grid>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                            <h6>DFV {fielddata['jaar']} : </h6>
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                            <h6><div style={{paddingLeft:'20px'}} >{fielddata['tdfv_af']}</div></h6>
                          </Grid>
                        </Grid>)                
            break;

          case 'tab_linkonkosten':
            if (fielddata.aang_optie === '1' || fielddata.job_id ===  'ec01cb3c87e4b5ce2fe28308fd9f2a7baf3' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">Gekoppelde onkosten</small>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            {this.state.linkonkosten.map(onkdata => {
                              return (
                                <Chip
                                  style={{marginTop:'5px',marginRight:'5px'}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  key={GenFunc.MakeId()}
                                  label={onkdata.description}
                                  onClick={() => this.GoToLinkJob(onkdata.id)}
                                />
                              );
                            })}                                  
                        </Grid>
                      </Grid>)
            }
            break;


          case 'dat_betaling':
          case 'aang_vperiode':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }

            if (fielddata.aang_optie === '1' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }
            break;

          case 'dat_doorstort':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }

            if (fielddata.aang_optie === '1' || fielddata.code.indexOf("VAT") > -1 || fielddata.code.indexOf("COR") > -1 )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }
            break;

         case 'dat_aang':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }

            if (fielddata.aang_optie === '1' || fielddata.aang_optie === '2' || fielddata.aang_optie === '3')
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }
            if (fielddata.code && (fielddata.code.indexOf("VAT") > -1  || fielddata.code.indexOf("COR") > -1  ))
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <small style={errstijl} className={errclass}>{description}</small>
                            <h6>{fielddata[fieldcode]}</h6>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', width:'100%', display: 'inline-block',cursor:'pointer'}}>
                              <small style={errstijl} className={errclass}>Gekoppeld document</small>
                              <h6 onClick={() => this.ClickDocument({id:fielddata.aang_pdf})} >{fielddata.aang_pdf_txt}</h6>
                            </div>
                          </Grid>
                      </Grid>)              
            }
            else
            {

            }

            break;

         case 'btw_betaald':
            if (fielddata.aang_optie === '1' )
            {

              if (fielddata.btw_betaald === '1' || fielddata.btw_betaald === true)
              {
                 factcheck = true;
              }
              if (this.state.edit === false)
              {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div>
                          <small className={errclass}>{description}: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                  control={<Checkbox checked={factcheck} />} />
                          </small>              
                      </div>
                      </Grid>)
              }
            }
            break;                     




          case 'nihil_datum':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }


            if (fielddata.aang_optie === '222' || fielddata.aang_optie === '223' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <small className={errclass}>{description}</small>
                          <h6>{fielddata[fieldcode]}</h6>
                      </Grid>)
            }
            break;

          case 'fact_id':
            if (this.state.edit === false)
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                      <h6 onClick={this.openInvoice} style={{ cursor: "pointer" }} id={fielddata.fact_id}>{fielddata.factnr}</h6>
                      </Grid>)
            }
            break;

          case 'tab_onkosten':
              veld = this.state.onkosten ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.onkostenkol}
                        data={this.state.onkosten} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobOnkost"      
                      />
                      </Grid>
                ) : null             
            break;

          case 'tab_vatref':
              veld = this.state.vatrefund ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.vatrefundkol}
                        data={this.state.vatrefund} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobVatrefundWf"      
                      />
                      </Grid>
                ) : null             
            break;


          case 'tab_periodes':
            let percheck1 = false
            let percheck2 = false
            let percheck3 = false
            let percheck4 = false
            let percheck5 = false
            let percheck6 = false
            let percheck7 = false
            let percheck8 = false
            let percheck9 = false
            let percheck10 = false
            let percheck11 = false
            let percheck12 = false

            if (fielddata.per1 === '1' || fielddata.per1 === true)
            {
              percheck1 = true;
            }
            if (fielddata.per2 === '1' || fielddata.per2 === true)
            {
              percheck2 = true;
            }
            if (fielddata.per3 === '1' || fielddata.per3 === true)
            {
              percheck3 = true;
            }
            if (fielddata.per4 === '1' || fielddata.per4 === true)
            {
              percheck4 = true;
            }
            if (fielddata.per5 === '1' || fielddata.per5 === true)
            {
              percheck5 = true;
            }
            if (fielddata.per6 === '1' || fielddata.per6 === true)
            {
              percheck6 = true;
            }
            if (fielddata.per7 === '1' || fielddata.per7 === true)
            {
              percheck7 = true;
            }
            if (fielddata.per8 === '1' || fielddata.per8 === true)
            {
              percheck8 = true;
            }
            if (fielddata.per9 === '1' || fielddata.per9 === true)
            {
              percheck9 = true;
            }
            if (fielddata.per10 === '1' || fielddata.per10 === true)
            {
              percheck10 = true;
            }
            if (fielddata.per11 === '1' || fielddata.per11 === true)
            {
              percheck11 = true;
            }
            if (fielddata.per12 === '1' || fielddata.per12 === true)
            {
              percheck12 = true;
            }

            veld = (<div key={'KJA'+fieldcode+this.state.refid} >
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={12} lg={12} style={{display: 'contents',textAlign:'center'}}>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M1</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M2</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M3</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M4</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M5</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M6</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M7</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M8</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M9</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M10</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M11</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M12</small>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={12} lg={12} style={{display: 'contents',textAlign:'center'}}>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck1} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck2} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck3} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck4} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck5} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck6} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck7} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck8} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck9} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck10} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck11} />} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <FormControlLabel style={{marginLeft:'0px',marginRight:'0px'}} disabled control={<Checkbox checked={percheck12} />} />
                        </Grid>
                      </Grid>
                    </Grid>

                  </div>
                )              

            break;

          case 'tab_dfv':
              veld =  this.state.dfvdata ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.dfvkol}
                        data={this.state.dfvdata} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDfv"      
                      />
                      </Grid>
                ) : null
            break;            

          case 'dfvdatalijst':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' && fielddata.kz_nihil === '1' )
            {
              veld =  this.state.dfvdatalijst ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.dfvkol}
                        data={this.state.dfvdatalijst} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDfv"      
                      />
                      </Grid>
                ) : null
            }
            break;            

          case 'tab_jvl':
              veld =  this.state.dfvdata ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.jvlkol}
                        data={this.state.jvldata} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobJvl"      
                      />
                      </Grid>
                ) : null
            break;            


          case 'tab_boetes':
              veld =  this.state.boetes ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.boeteskol}
                        data={this.state.boetes} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobBoetes"      
                      />
                      </Grid>
                ) : null
            break;            


          case 'tab_douane':
              veld =  this.state.douanes ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.douaneskol}
                        data={this.state.douanes} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDouanes"      
                      />
                      </Grid>
                ) : null
            break;            

         case 'forfait':
              if (fielddata.forfait === '1' || fielddata.forfait === true)
              {
                 factcheck = true;
              }
              if (this.state.edit === false)
              {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div>
                          <small className={errclass}>{description}: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                  control={<Checkbox checked={factcheck} />} />
                          </small>              
                      </div>
                      </Grid>)
              }
              break;                     




          case 'facturatie':
              if (fielddata.facturatie === '1' || fielddata.facturatie === true)
              {
                 factcheck = true;
              }
              if (this.state.edit === false)
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                          <small className="text-muted p-t-30 db">{description}: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                  control={<Checkbox checked={factcheck} />} />
                          </small>              
                      </Grid>)
              }
              break;                     

          case 'contr_uren':
            let ctrvalue = parseFloat(fielddata[fieldcode])
            if (isNaN(ctrvalue)) 
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                      </Grid>)
            }
            else
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6>{ctrvalue.toLocaleString()}</h6>
                    </Grid>)
            }
            break;



          case 'tarief':
            let prefix = '€';
            let value = parseFloat(fielddata[fieldcode])
            if (isNaN(value)) 
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                      </Grid>)
            }
            else
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6>{prefix} {value.toLocaleString()}</h6>
                    </Grid>)
            }
            break;

          case 'vat_tarief':
            let vatprefix = '%';
            let vttvalue = parseFloat(fielddata[fieldcode])
            if (isNaN(vttvalue) || vttvalue === 0) 
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                      </Grid>)
            }
            else
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6>{fielddata[fieldcode]} {vatprefix}</h6>
                    </Grid>)
            }
            break;

          case 'vat_basisfact':
          case 'terug_bedr':

            numval = (fielddata[fieldcode] && fielddata[fieldcode].constructor === String) ? parseFloat(fielddata[fieldcode]) : fielddata[fieldcode];
            let numval_val = (fielddata['terug_bedr_val'] && fielddata['terug_bedr_val'].constructor === String) ? parseFloat(fielddata['terug_bedr_val']) : fielddata['terug_bedr_val'];

            if (isNaN(numval)) {numval = 0}
            if (isNaN(numval_val)) {numval_val = 0}
              if (typeof numval_val === 'number') 
              {
                numval_val = numval_val.toFixed(2)

              }
              if (typeof numval === 'number') 
              {
                numval = numval.toFixed(2)
              }


            if (numval === 0) 
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                      </Grid>)
            }
            else
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      { fielddata.munt !== 'EUR' && (fieldcode === 'terug_bedr' || fieldcode === 'vat_basisfact') && 
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>
                            <small className={errclass}>{description}</small>
                            <h6>{numval_val.toLocaleString()} {fielddata.munt}</h6>
                          </div>
                          <div style={{ marginLeft: "50px" }}>
                            <small className={errclass}>Koers</small>
                            <h6>{fielddata.koers}</h6>
                          </div>
                          <div style={{ marginLeft: "50px" }}>
                            <small className={errclass}>Bedrag in €</small>
                            <h6>{(numval_val / Number(fielddata.koers)).toFixed(2)}</h6>
                          </div>
                        </div>
                      }
                      { fielddata.munt === 'EUR' && (fieldcode === 'terug_bedr' || fieldcode === 'vat_basisfact') &&
                        <div>
                          <small className={errclass}>{description}</small>
                          <h6>{numval.toLocaleString()} EUR</h6>
                        </div>
                      }
                    </Grid>)              
            }
  
            break;

          case 'vat_factextra':
          case 'vat_facttot':
            numval = (fielddata[fieldcode] && fielddata[fieldcode].constructor === String) ? parseFloat(fielddata[fieldcode]) : fielddata[fieldcode];
            if (isNaN(numval)) {numval = 0}
            if (typeof numval === 'number') 
            {
              numval = numval.toFixed(2)
            }


            if (numval === 0) 
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <small className={errclass}>{description}</small>
                      </Grid>)
            }
            else
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <small className={errclass}>{description}</small>
                        <h6>{numval.toLocaleString()}</h6>
                    </Grid>)
            }
            break;



          case 'account_name':
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small style={errstijl} className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToMain('ACC',fielddata)} style={{cursor:'pointer'}}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            break;

          case 'regnummer':
          case 'dfv_regnummer':          
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small style={errstijl} className={errclass}>Registratie nummer</small>
                        <h6 onClick={() => this.GoToMain('REG',fielddata)} style={{cursor:'pointer'}}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            break;

          case 'bankid':
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <small style={errstijl} className={errclass}>{description}</small>
                        <h6>{fielddata['banktxt']}</h6>
                    </Grid>)
            break;

          case 'corr_vat_aan_id':
            if (fielddata['corr_vat_aan_id'] && fielddata['corr_vat_aan_id'].length > 4)
            {
                if (fielddata.vat_overname === '1' || fielddata.vat_overname === true)
                {
                   factcheck = true;
                }
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'left', width:'100%', display: 'inline-block',cursor:'pointer'}}>
                              <small style={errstijl} className={errclass}>Correctie van aangifte</small>
                              <h6 onClick={() => this.GoToLinkJob(fielddata.corr_vat_aan_id)}>{fielddata['corr_vat_aan_txt']}</h6>
                            </div>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">Overname Data: 
                              <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                 control={<Checkbox checked={factcheck} />} />
                            </small>              
                          </Grid>
                      </Grid>)                
            }
            break;

          case 'vat_overname':
          case 'correctie':
          case 'correctie_txt':

            veld =  null;
            break;


          default:    
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass} style={errstijl} >{description}</small>
                        <h6>{fielddata[fieldcode]}</h6>
                    </Grid>)
            break;
        } 
      }
      else
      {
        switch (fieldcode) 
        {
          case 'start_date':
          case 'start_date_new':
          case 'end_date':
          case 'corr_dat_correctie':
          case 'corr_dat_ind_correctie':
          case 'dat_toek_aanv':
          case 'resp_date':

            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditDateTime className="card-title m-t-10"
                              value={fielddata[fieldcode]}
                              coltype = 'STR'                        
                              labelText={description}
                              inputProps={{value:fielddata[fieldcode]}}
                              formControlProps={{fullWidth: true}}
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;

          case 'dfv_import':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleDfvImport()}>
                             Opladen lijst
                          </Button>
                        </div>
                      </Grid>)
            break;

          case 'xml_vataang':
            if (fielddata.acc_country === '1')
            {
              veld =  ( <Grid key={'KJAM'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid key={'KJA1'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleXmlListing()}>
                                Maken XML Bestand
                              </Button>
                            </div>
                          </Grid>
                          <Grid key={'KJA2'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handlePdfListing()}>
                                Maken PDF Listing
                              </Button>
                            </div>
                          </Grid>                          
                        </Grid>
                      )
            }
            else
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handlePdfListing()}>
                             Maken PDF Listing
                          </Button>
                        </div>
                      </Grid>)

            }
            break;

          case 'klant_jaar_listing':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handlePdfListingKlant()}>
                             Maken lijst 
                          </Button>
                        </div>
                      </Grid>)
                break


          case 'make_onkosten_pdf_listing':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleOnkListing()}>
                             Maken lijst onkosten
                          </Button>
                        </div>
                      </Grid>)
                break


          case 'crea_inlichting':
            if (fielddata.link_jobid && fielddata.link_jobid.length > 4)
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} style={{cursor:'pointer'}} item sm={6} md={6} lg={6}>
                          <small className={errclass}>Link job vraag voor inlichtingen</small>
                        <h6 onClick={() => this.GoToLinkJob(fielddata.link_jobid)}>{fielddata['link_jobtxt']}</h6>
                    </Grid>)
            }
            else
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.CreaVoi()}>
                             Maak vraag voor inlichtingen (VOI)
                          </Button>
                        </div>
                      </Grid>)

            }
            break;


          case 'pdf_scanvat':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handlePdfScanVat()}>
                             Maken PDF bestand
                          </Button>
                        </div>
                      </Grid>)
            break;



          case 'dfv_creadoc':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleDfvDoc()}>
                             Document oproepen
                          </Button>
                        </div>
                      </Grid>)
            break;

          case 'jvl_import':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleJvlImport()}>
                             Opladen lijst
                          </Button>
                        </div>
                      </Grid>)
            break;


          case 'jvl_creadoc':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleJvlDoc()}>
                             Document oproepen
                          </Button>
                        </div>
                      </Grid>)
            break;


          case 'dok':
            if (fielddata.dok && fielddata.dok.length > 5)
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{width:'100%', textAlign:'left', overflowY:'auto', display:'inline-block',height:'73vh',cursor:'pointer'}} 
                            dangerouslySetInnerHTML={{ __html: fielddata.dok}}  onClick={() => {this.DokClick()}}>
                        </div>
                      </Grid>)
            }
            else
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      </Grid>)

            }
            break;


          case 'dfv_periodes':
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>

                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={0}
                                labelText={description}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                        </Grid>)
            break;            

          case 'create_date':
           veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className="text-muted p-t-30 db">{description}</small>
                        <h6>{fielddata[fieldcode]}</h6>
                    </Grid>)            
            break;
          case 'tab_linkonkosten':
            if (fielddata.aang_optie === '1' || fielddata.job_id ===  'ec01cb3c87e4b5ce2fe28308fd9f2a7baf3' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">Gekoppelde onkosten</small>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            {this.state.linkonkosten.map(onkdata => {
                              return (
                                <Chip
                                  style={{marginTop:'5px',marginRight:'5px'}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  key={GenFunc.MakeId()}
                                  label={onkdata.description}
                                  onClick={() => this.GoToLinkJob(onkdata.id)}
                                  onDelete={() => this.DeleteLinkJob(onkdata)}
                                />
                              );
                            })}                                  
                        </Grid>
                      </Grid>)
            }
            break;
          case 'tab_onkosten':
              veld = this.state.onkosten ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.onkostenkol}
                        data={this.state.onkosten} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobOnkost"      
                      />
                      </Grid>
                ) : null             
            break;

          case 'tab_vatref':
              veld = this.state.vatrefund ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.vatrefundkol}
                        data={this.state.vatrefund} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobVatrefundWf"      
                      />
                      </Grid>
                ) : null             
            break;


          case 'tab_dfv':
              veld =  this.state.dfvdata ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} onClick={this.TableDfvEdit} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.dfvkol}
                        data={this.state.dfvdata} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDfv"      
                      />
                      </Grid>
                ) : null

            break;

          case 'dfvdatalijst':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' && fielddata.kz_nihil === '1' )
            {

              veld =  this.state.dfvdatalijst ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.dfvkol}
                        data={this.state.dfvdatalijst} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDfv"      
                      />
                      </Grid>
                ) : null
            }
            break;            

 
          case 'tab_jvl':
              veld =  this.state.jvldata ? (<Grid key={'KJA'+fieldcode+this.state.refid} style={{marginBottom:'50px'}} onClick={this.TableJvlEdit} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.jvlkol}
                        data={this.state.jvldata} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobJvl"      
                      />
                      </Grid>
                ) : null

            break;

          case 'tab_onkostenold':
            veld =  this.state.onkosten ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.onkostenkol}
                        data={this.state.onkosten} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        filter={false}
                        addRecord={(params,value,error) => this.addRecord(params,value,error)}
                        getFilter={(params) => this.getFilter(params)}
                        CellValueChanged={(params,waarde,object) => this.SubOnkostenChanged(params,waarde,object)}
                        CellValueCheck={(params,value,error) => this.SubOnkostenCheck(params,value,error)}
                        CellClickedEvent={(column, colDef, value) => this.SubOnkostenCellClick(column,colDef, value)}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel="JobOnkost"      
                        pinnedTopRowData={[{id:0,job_id:0,supplier_id:'',type_onkost:'',country:'',factnum:'',factdat:'',mvh:'',btw:'',totaal:'',country_txt:'',supplier_txt:'',type_onkost_txt:'',save:'',verwijder:''}] }
                        GridReady={this.onGridReady}
                      />
                      </Grid>
                ) : null;

                break;            

          case 'tab_boetes':
              veld =  this.state.boetes ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.boeteskol}
                        data={this.state.boetes} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobBoetes"      
                      />
                      </Grid>
                ) : null
            break;            

          case 'tab_boetesold':
            veld =  this.state.boetes ?  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.boeteskol}
                        data={this.state.boetes} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        filter={false}
                        addRecord={(params,value,error) => this.addRecord(params,value,error)}
                        getFilter={(params) => this.getFilter(params)}
                        CellValueChanged={(params,waarde,object) => this.SubBoetesChanged(params,waarde,object)}
                        CellValueCheck={(params,value,error) => this.SubBoetesCheck(params,value,error)}
                        CellClickedEvent={(column, colDef, value) => this.SubBoetesCellClick(column,colDef, value)}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel="Jobboetes"      
                        pinnedTopRowData={[{id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''}] }
                        GridReady={this.onGridReady}
                      />
                      </Grid>
                ) : null

                break;            
          case 'tab_douane':
              veld =  this.state.douanes ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.douaneskol}
                        data={this.state.douanes} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={true}
                        filter={false}
                        headerComponentParams = {null}
                        suppressColumnVirtualisation={true}
                        editable={false}
                        sleutel="JobDouanes"      
                      />
                      </Grid>
                ) : null
            break;            


          case 'tab_douaneold':
            veld =  this.state.douanes ? (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight="300px" 
                        columns={this.state.douaneskol}
                        data={this.state.douanes} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        filter={false}
                        addRecord={(params,value,error) => this.addRecord(params,value,error)}
                        getFilter={(params) => this.getFilter(params)}
                        CellValueChanged={(params,waarde,object) => this.SubDouanesChanged(params,waarde,object)}
                        CellValueCheck={(params,value,error) => this.SubDouanesCheck(params,value,error)}
                        CellClickedEvent={(column, colDef, value) => this.SubDouanesCellClick(column,colDef, value)}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel="JobDouanes"      
                        pinnedTopRowData={[{id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''}] }
                        GridReady={this.onGridReady}
                      />
                      </Grid>
                ) : null

                break;            

          case 'tab_periodes':
            let percheck1 = false
            let percheck2 = false
            let percheck3 = false
            let percheck4 = false
            let percheck5 = false
            let percheck6 = false
            let percheck7 = false
            let percheck8 = false
            let percheck9 = false
            let percheck10 = false
            let percheck11 = false
            let percheck12 = false

            if (fielddata.per1 === '1' || fielddata.per1 === true)
            {
              percheck1 = true;
            }
            if (fielddata.per2 === '1' || fielddata.per2 === true)
            {
              percheck2 = true;
            }
            if (fielddata.per3 === '1' || fielddata.per3 === true)
            {
              percheck3 = true;
            }
            if (fielddata.per4 === '1' || fielddata.per4 === true)
            {
              percheck4 = true;
            }
            if (fielddata.per5 === '1' || fielddata.per5 === true)
            {
              percheck5 = true;
            }
            if (fielddata.per6 === '1' || fielddata.per6 === true)
            {
              percheck6 = true;
            }
            if (fielddata.per7 === '1' || fielddata.per7 === true)
            {
              percheck7 = true;
            }
            if (fielddata.per8 === '1' || fielddata.per8 === true)
            {
              percheck8 = true;
            }
            if (fielddata.per9 === '1' || fielddata.per9 === true)
            {
              percheck9 = true;
            }
            if (fielddata.per10 === '1' || fielddata.per10 === true)
            {
              percheck10 = true;
            }
            if (fielddata.per11 === '1' || fielddata.per11 === true)
            {
              percheck11 = true;
            }
            if (fielddata.per12 === '1' || fielddata.per12 === true)
            {
              percheck12 = true;
            }

            veld = (<div key={'KJA'+fieldcode+this.state.refid} >
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={12} lg={12} style={{display: 'contents',textAlign:'center'}}>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M1</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M2</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M3</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M4</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M5</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M6</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M7</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M8</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M9</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M10</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M11</small>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <small className="text-muted p-t-30 db">M12</small>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={12} lg={12} style={{display: 'contents',textAlign:'center'}}>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck1}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per1')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck2}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per2')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck3}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per3')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck4}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per4')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck5}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per5')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck6}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per6')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck7}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per7')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck8}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per8')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck9}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per9')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck10}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per10')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck11}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per11')}
                          />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1} style={{textAlign:'center'}}>
                          <EditCheck Objstyle={{marginLeft:'0px',marginRight:'0px',width:'100%'}} className="card-title m-t-10" value={percheck12}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'per12')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                  </div>
                )              

            break;


          case 'tot_onkosten':
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                      <small className="text-muted p-t-30 db">{description}</small>
                      <h6>{fielddata[fieldcode]}</h6>
                     </Grid>
                )
                break;      



          case 'tot_boetes':
              totvatmvhvalue = parseFloat(fielddata.tot_boetes)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_boetes_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }
              if (typeof totvatmvhvalue === 'number') 
              {
                totvatmvhvalue = totvatmvhvalue.toFixed(2)
              }


                veld =  (
                     <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                     {fielddata.munt}
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;



          case 'tot_douane':
              totvatmvhvalue = parseFloat(fielddata.tot_douane)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_douane_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }
              if (typeof totvatmvhvalue === 'number') 
              {
                totvatmvhvalue = totvatmvhvalue.toFixed(2)

              }


                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;

          case 'algtot_onkosten':
              totvatmvhvalue = parseFloat(fielddata.algtot_onkosten)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.algtot_onkosten_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}
              if (typeof totvatmvhvalue_val === 'number') 
              {
                totvatmvhvalue_val = totvatmvhvalue_val.toFixed(2)
              }
              if (typeof totvatmvhvalue === 'number') 
              {
                totvatmvhvalue = totvatmvhvalue.toFixed(2)

              }

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                      {fielddata.munt !== 'EUR' &&
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                      </Grid>
                      }
                    </Grid>)
              break;





          case 'aang_optie':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel component="legend">Aangifteopties</FormLabel>
                            <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="aangifte" name="aangifte" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.aang_optie} >
                              <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Aangifte met saldo" />
                              <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Nihil met ritten" />
                              <FormControlLabel value="3" labelPlacement="start" control={<Radio color="primary" />} label="Nihil zonder ritten" />
                            </RadioGroup>                            
                           </FormControl>
                      </Grid>)
            break;

          case 'terug_optie':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel component="legend">Teruggaveopties</FormLabel>
                            <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="terugoptie" name="terugoptie" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.terug_optie} >
                              <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Teruggave 100% geaccepteerd" />
                              <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Teruggave niet 100% geaccepteerd" />
                            </RadioGroup>                            
                           </FormControl>
                      </Grid>)
            break;

          case 'bankid':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <EditSelect className="card-title m-t-10"
                          value={fielddata[fieldcode]}
                          labelText={description}
                          data={this.state.data}
                          type='AllRekeningen'
                          colId='banktxt'
                          selectcel='bankid'
                          extramenu = {[{id:'fa042499996d0262c3925617b0c72bb20eeb1d',text:fielddata['vatbank'],reknummer:fielddata['vatbankiban'], bic:fielddata['vatbankbic']}]}
                          id={fielddata.bankid}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;


          case 'terug_actie':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditSelect className="card-title m-t-10"
                          value={fielddata[fieldcode]}
                          labelText={description}
                          data={this.state.data}
                          type='AllTerugActie'
                          colId='terug_actie_txt'
                          selectcel='terug_actie'
                          id={fielddata.terug_actie}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            }
            break;

          case 'terug_remarks':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText='Opmerkingen'
                            multiline={true}   
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            }
            break;

          case 'vat_factopm':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText='Opmerkingen'
                            multiline={true}   
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;


          case 'dat_ontv_terug':
                veld =  (
                         <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>)
            break;

          case 'dat_bezwaar':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleLinkPdf(workflowstorie,'pdf_bezwaar')}>
                                Koppelen PDF
                              </Button>
                            </div>
                          </Grid>
                      </Grid>)
              }
            }
            break;

          case 'respons_bezwaar':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleLinkPdf(workflowstorie,'pdf_respons')}>
                                Koppelen PDF
                              </Button>
                            </div>
                          </Grid>
                      </Grid>)
              }
            }
            break;


          case 'def_beslis_aanv':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
                veld =  (                     
                        <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={12} md={12} lg={12}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Definitieve beslissing</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="bzwaanvaard" name="bzwaanvaard" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.def_beslis_aanv} >
                                  <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Bezwaar 100% aanvaard" />
                                  <FormControlLabel value="0" labelPlacement="start" control={<Radio color="primary" />} label="Bezwaar niet 100% aanvaard" />
                                </RadioGroup>                            
                               </FormControl>
                          </Grid>
                        </Grid>)
              }
            }
            break;

          case 'vat_overname':
            if (fielddata['corr_vat_aan_id'] && fielddata['corr_vat_aan_id'].length > 4)
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText='Overname data ?'                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)              
            }
            break;


          case 'def_beslis_bedrag':
            if (fielddata.terug_optie === '2' || fielddata.terug_optie === 2)
            {            
              if (fielddata.terug_actie === '3' || fielddata.terug_actie === 3)
              {            
                 veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={4}
                                labelText={description}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                          </Grid>
                        </Grid>)
              }
            }
            break;




          case 'terug_aanv':
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText='100% Aanvaard'                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
              break;                     


          case 'afronden':
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText='Afronden'                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
              break;                     


          case 'terug_date':
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                          </Grid>
                      </Grid>)
            break;


          case 'terug_bedr99':
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={4}
                                labelText={description}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                          </Grid>
                        </Grid>)
            break;



          case 'vat_tarief':
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small className={errclass}>{description}</small>
                        <h6>{fielddata[fieldcode]} %</h6>
                    </Grid>)
            break;

          case 'vat_basisfact':

             if (fielddata.munt === 'EUR') {
              veld = (
                <Grid key={'KJA' + fieldcode + this.state.refid} item sm={6} md={6} lg={6}>
                  <div style={{ marginTop: '-4px' }}>
                    <EditString className="card-title m-t-10"
                      value={fielddata[fieldcode]}
                      coltype='NUM'
                      decimal={4}
                      labelText={description}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, fieldcode, verplicht)}
                      setError={(params, value, type) => this.ErrorCheck(params, fielddata[fieldcode], type, fieldcode, verplicht)}
                    />
                  </div>
                </Grid>)
            } else {
              veld = (
                <Grid key={'KJA' + fieldcode + this.state.refid} item sm={6} md={6} lg={6}>
                  <div style={{ marginTop: '-4px', display: "flex", flexDirection: "row" }}>
                    <EditString className="card-title m-t-10"
                      value={fielddata[fieldcode]}
                      coltype='NUM'
                      decimal={4}
                      labelText={description}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, fieldcode, verplicht)}
                      setError={(params, value, type) => this.ErrorCheck(params, fielddata[fieldcode], type, fieldcode, verplicht)}
                    />
                    <EditString className="card-title m-t-10"
                      value={fielddata['vat_koers']}
                      coltype='NUM'
                      decimal={2}
                      labelText={"Koers"}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'vat_koers', verplicht)}
                      setError={(params, value, type) => this.ErrorCheck(params, fielddata['vat_koers'], type, 'vat_koers', verplicht)}
                    />
                  </div>
                </Grid>
              )
            }
                
                
            break;

          case 'terug_bedr':
              let tbedrlabt1 = description + ' (EUR)';
              let tbedrlabt2 = description + ' (' + fielddata['munt'] +')';

             if (fielddata.munt === 'EUR') {
              veld = (
                <Grid key={'KJA' + fieldcode + this.state.refid} item sm={6} md={6} lg={6}>
                  <div style={{ marginTop: '-4px' }}>
                    <EditString className="card-title m-t-10"
                      value={fielddata[fieldcode]}
                      coltype='NUM'
                      decimal={4}
                      labelText={tbedrlabt1}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, fieldcode, false)}
                      setError={(params, value, type) => this.ErrorCheck(params, fielddata[fieldcode], type, fieldcode, verplicht)}
                    />
                  </div>
                </Grid>)
            } else {
              veld = (
                <Grid key={'KJA' + fieldcode + this.state.refid} item sm={6} md={6} lg={6}>
                  <div style={{ marginTop: '-4px', display: "flex", flexDirection: "row" }}>
                    <EditString className="card-title m-t-10"
                      value={fielddata['terug_bedr_val']}
                      coltype='NUM'
                      decimal={2}
                      labelText={tbedrlabt2}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'terug_bedr_val', false)}
                      setError={false}

                    />
                    <EditString className="card-title m-t-10"
                      value={fielddata['terug_bedr']}
                      coltype='NUM'
                      decimal={2}
                      labelText={tbedrlabt1}
                      ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'terug_bedr', false)}
                      setError={false}

                    />
                  </div>
                </Grid>
              )
            }
                
                
            break;

          case 'vat_factextra':
          case 'vat_facttot':
                veld =  (
                          <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={4}
                                labelText={description}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>)
            break;


          case 'kz_nihil':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Keuzemogelijkhied</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kznihil" name="kznihil" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.kz_nihil} >
                                  <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Periodieke aangifte" />
                                  <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Vastgestelde DFV door DEU" />
                                </RadioGroup>                            
                               </FormControl>
                          </Grid>)
            }
            else
            {
              if (fielddata.aang_optie === '2')
              {          
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Keuzemogelijkhied</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kznihil" name="kznihil" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.kz_nihil} >
                                  <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Ritten aangegeven in een andere aangifte" />
                                  <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Nihil aangifte" />
                                </RadioGroup>                            
                               </FormControl>
                          </Grid>)
              }
            }

            break;

          case 'corr_door':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel component="legend">Ingediend door</FormLabel>
                            <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="kzdoor" name="kzdoor" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.corr_door} >
                              <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Buitenlands BTW Kantoor" />
                              <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Brutax" />
                            </RadioGroup>                            
                           </FormControl>
                      </Grid>)
            break;


          case 'saldotebet':
            if (fielddata.aang_optie === '1' )
            {
              let value = parseFloat(fielddata[fieldcode])
              muntvalue = parseFloat(fielddata.saldotebet_val);
              if (isNaN(muntvalue)) {muntvalue = 0}
              if (typeof muntvalue === 'number') 
              {
                muntvalue = muntvalue.toFixed(2)
              }

              if (isNaN(value)) 
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }

                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">{description}</small>              
                            <h6>0 EUR</h6>
                          </Grid>
                        </Grid>)

              }
              else
              {
                if (value > 0)
                {

                  veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'red'}} item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small style={{color:'red'}} className="text-muted p-t-30 db">{description}</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'red'}} item sm={6} md={6} lg={6}>
                            <small  style={{color:'red'}} className="text-muted p-t-30 db">{description}</small>              
                            <h6>{value.toLocaleString()} EUR</h6>
                          </Grid>
                        </Grid>)                

                }
                else
                {
                  value = value * -1;
                  muntvalue = muntvalue * -1;
                  veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'green'}} item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' &&
                              <div>
                                <small  style={{color:'green'}} className="text-muted p-t-30 db">Saldo in voordeel :</small>              
                                <h6>{muntvalue.toLocaleString()} {fielddata.munt}</h6>
                              </div>
                            }
                          </Grid>
                          <Grid style={{textAlign: 'left',paddingRight:'20px', color:'green'}} item sm={6} md={6} lg={6}>
                            <small  style={{color:'green'}} className="text-muted p-t-30 db">Saldo in voordeel :</small>              
                            <h6>{value.toLocaleString()} EUR</h6>
                          </Grid>
                        </Grid>)                
  
                }
              }
            }
            break;


          case 'omzetbel':
          case 'verwijlintrest':
          case 'dir_mvh':
          case 'voorafbetaling':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              if (fieldcode === 'omzetbel')
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                  <Grid item sm={6} md={6} lg={6}>
                    <div>
                      <h6>DFV</h6>
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>

                    <div style={{marginTop: '-4px'}}>
                      <EditString className="card-title m-t-10"
                        value={fielddata[fieldcode]}
                        coltype = 'NUM'                        
                        decimal={0}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                        setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                      />
                    </div>
                  </Grid>
                </Grid> )            
              }
              if (fieldcode === 'verwijlintrest')
              {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                  <Grid item sm={6} md={6} lg={6}>

                    <div style={{marginTop: '-4px'}}>
                      <EditString className="card-title m-t-10"
                        value={fielddata[fieldcode]}
                        labelText={description}
                        coltype = 'NUM'                        
                        decimal={0}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                        setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                      />
                    </div>
                  </Grid>
                </Grid> )            
              }

            }
            if (fielddata.aang_optie === '1' )
            {
                let desceur = description + ' EUR';
                let descval = description + ' ' + fielddata.munt ;

                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid style={{textAlign: 'right',paddingRight:'20px'}} item sm={6} md={6} lg={6}>
                            {fielddata.munt !== 'EUR' && fieldcode === 'dir_mvh' && 
                              <div style={{marginTop: '-4px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.dir_mvh_val}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  labelText={descval}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dir_mvh_val',verplicht)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata.dir_mvh_val,type,'dir_mvh_val',verplicht)}
                                />
                              </div>                            
                            }
                            {fielddata.munt !== 'EUR' && fieldcode === 'omzetbel' && 
                              <div style={{marginTop: '-4px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.omzetbel_val}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  labelText={descval}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'omzetbel_val',verplicht)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata.omzetbel_val,type,'omzetbel_val',verplicht)}
                                />
                              </div>                            
                            }
                            {fielddata.munt !== 'EUR' && fieldcode === 'voorafbetaling' && 
                              <div style={{marginTop: '-4px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.voorafbetaling_val}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  labelText={descval}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'voorafbetaling_val',verplicht)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata.voorafbetaling_val,type,'voorafbetaling_val',verplicht)}
                                />
                              </div>                            
                            }

                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={4}
                                labelText={desceur}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                        </Grid>)
            }
            break;


          case 'verwijlintrest????':
            if (fielddata.aang_optie === '1' )
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditString className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'NUM'                        
                                decimal={4}
                                labelText={description}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                        </Grid>)
            }
            break;

          case 'corr_tabel':
              let omzetbel = parseFloat(fielddata.omzetbel)
              let voorbel = parseFloat(fielddata.voorbel)
              let saldotebet = parseFloat(fielddata.saldotebet)

              let corr_omzbel = parseFloat(fielddata.corr_omzbel)
              let corr_verwijl = parseFloat(fielddata.corr_verwijl)
              let corr_voorbel = parseFloat(fielddata.corr_voorbel)
              let corr_tebet = parseFloat(fielddata.corr_tebet)

              if (isNaN(omzetbel)) {omzetbel = 0}
              if (isNaN(voorbel)) {voorbel = 0}
              if (isNaN(saldotebet)) {saldotebet = 0}
              if (isNaN(corr_omzbel)) {corr_omzbel = 0}
              if (isNaN(corr_verwijl)) {corr_verwijl = 0}
              if (isNaN(corr_voorbel)) {corr_voorbel = 0}
              if (isNaN(corr_tebet)) {corr_tebet = 0}

              let gecorr_omzetbel = corr_omzbel - omzetbel
              let gecorr_voorbel = corr_voorbel - voorbel
              let corr_tebetaal = corr_omzbel - corr_voorbel
              let gecorr_tebet = corr_tebetaal - saldotebet

              veld =  (<div key={'KJA'+fieldcode+this.state.refid} style={{width:'100%'}}>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Initiële</h6>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Correctie</h6>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Gecorrigeerde</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Aangifte</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid  style={{textAlign:'center'}} item sm={3} md={3} lg={3}>
                              <h6>Aangifte</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Omzetbelasting :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{omzetbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <div style={{marginTop: '-30px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.corr_omzbel}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'corr_omzbel',false)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                />
                              </div>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_omzetbel.toLocaleString()}</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Voorbelasting :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{voorbel.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <div style={{marginTop: '-30px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.corr_voorbel}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'corr_voorbel',false)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                />
                              </div>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_voorbel.toLocaleString()}</h6>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Saldo te betalen :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{saldotebet.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{corr_tebetaal.toLocaleString()}</h6>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{gecorr_tebet.toLocaleString()}</h6>
                            </Grid>
                          </Grid>
                          <div></div>
                        <Grid  style={{paddingTop:'30px'}} container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Bedrag te betalen :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'right'}} item sm={3} md={3} lg={3}>
                              <div style={{marginTop: '-30px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.corr_tebet}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'corr_tebet',false)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Datum Betaling :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                              {fielddata.corr_tebet > 0 &&
                              <div style={{marginTop: '-30px'}}>
                                <EditDateTime className="card-title m-t-10"
                                    value={fielddata.corr_dat_bet}
                                    coltype = 'STR'                        
                                    inputProps={{value:fielddata.corr_dat_bet}}
                                    formControlProps={{fullWidth: true}}
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'corr_dat_bet',false)}
                                    setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                />
                              </div>
                              }
                              {fielddata.corr_tebet < 0.1 &&
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{fielddata.corr_dat_bet}</h6>
                              }



                            </Grid>
                          </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} md={3} lg={3}>
                            Verwijlintrest :
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid item sm={3} md={3} lg={3}>
                            </Grid>
                            <Grid style={{textAlign:'right'}} item sm={3} md={3} lg={3}>
                              {fielddata.corr_tebet > 0 &&
                              <div style={{marginTop: '-30px'}}>
                                <EditString className="card-title m-t-10"
                                  value={fielddata.corr_verwijl}
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'corr_verwijl',false)}
                                  setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                />
                              </div>
                              }
                              {fielddata.corr_tebet < 0.1 &&
                              <h6 style={{float: 'right',right:'30%',position: 'relative'}}>{fielddata.corr_verwijl}</h6>
                              }

                            </Grid>
                          </Grid>


                        </div>)

            break;            
 

          case 'voorbel':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                  <Grid item sm={6} md={6} lg={6}>
                    <div>
                      <h6>Steuer</h6>
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>

                    <div style={{marginTop: '-4px'}}>
                      <EditString className="card-title m-t-10"
                        value={fielddata[fieldcode]}
                        coltype = 'NUM'                        
                        decimal={2}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                        setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                      />
                    </div>
                  </Grid>
                </Grid> )            
            }

            if (fielddata.aang_optie === '1')
            {
              totvatmvhvalue = parseFloat(fielddata.voorbel)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.voorbel_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR
                            <div style={{marginTop:'20px', paddingLeft:'20px'}} >
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.GetOnkosten()}>
                                Lijst onkosten
                              </Button>
                            </div>
                          </h6>
                      </Grid>
                    </Grid>)
              }
            if (fielddata.job_id ===  'ec01cb3c87e4b5ce2fe28308fd9f2a7baf3' )
            {
                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop:'20px', paddingLeft:'20px'}} >
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.GetOnkosten()}>
                                Lijst onkosten
                              </Button>
                            </div>
                      </Grid>
                    </Grid>)
              }

              break;



          case 'tot_link_boetes':
            if (fielddata.aang_optie === '1' )
            {
              totvatmvhvalue = parseFloat(fielddata.tot_link_boetes)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_link_boetes_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
              break;


          case 'tot_link_douane':
            if (fielddata.aang_optie === '1' )
            {
              totvatmvhvalue = parseFloat(fielddata.tot_link_douane)
              if (isNaN(totvatmvhvalue)) {totvatmvhvalue = 0}
              totvatmvhvalue_val = parseFloat(fielddata.tot_link_douane_val)
              if (isNaN(totvatmvhvalue_val)) {totvatmvhvalue_val = 0}

                veld =  (
                    <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                          {fielddata.munt !== 'EUR' &&
                            <div>
                              <small className="text-muted p-t-30 db">{description}</small>              
                              <h6>{totvatmvhvalue_val.toLocaleString()} {fielddata.munt}</h6>
                            </div>
                          }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">{description}</small>              
                          <h6>{totvatmvhvalue.toLocaleString()} EUR</h6>
                      </Grid>
                    </Grid>)
              }
              break;




          case 'dat_ontv_geg':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata.dat_ontv_geg}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata.dat_ontv_geg}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
              break;


          case 'dat_betaling':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }

            if (fielddata.aang_optie === '1' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }
            break;

          case 'dat_doorstort':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }

            if (fielddata.aang_optie === '1' ||   fielddata.code.indexOf("VAT") > -1 ||   fielddata.code.indexOf("COR") > -1 )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }
            break;


          case 'dat_aang':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }

            if (fielddata.aang_optie === '1' || fielddata.aang_optie === '2' || fielddata.aang_optie === '3')
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }
            if (fielddata.code && (fielddata.code.indexOf("VAT") > -1  || fielddata.code.indexOf("COR") > -1  ))
            {
                veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                         <Grid item sm={6} md={6} lg={6}>
                            <div style={{marginTop: '-4px'}}>
                              <EditDateTime className="card-title m-t-10"
                                value={fielddata[fieldcode]}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata[fieldcode]}}
                                formControlProps={{fullWidth: true}}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                              <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleLinkPdf(workflowstorie,'aang_pdf')}>
                                Koppelen PDF
                              </Button>
                            </div>
                          </Grid>
                      </Grid>)

            }


            break;

          case 'btw_betaald':
            if (fielddata.aang_optie === '1' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText={description}                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            }
            break;    

          case 'nihil_datum':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata.nihil_datum}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata.nihil_datum}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }          
            if (fielddata.aang_optie === '2222' ||  fielddata.aang_optie === '22223')
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata.start_date}
                                coltype = 'STR'                        
                                labelText={description}
                                inputProps={{value:fielddata.start_date}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }
            break;

          case 'contr_afhand':
            veld =  ( <div key={'KJA'+fieldcode+this.state.refid} style={{width:'100%'}}>
                        <Grid container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <FormControl component="fieldset" style={{width: '100%'}}>
                              <FormLabel component="legend">Afhandeling</FormLabel>
                                <RadioGroup style={{width: '100%',flexWrap: 'nowrap', display:'block',textAlign:'center'}} row  aria-label="afhandeling" name="afhandeling" onChange={(event) => this.ValueCheck(event,'','',fieldcode,verplicht)} value={fielddata.contr_afhand} >
                                  <FormControlLabel value="1" labelPlacement="start" control={<Radio color="primary" />} label="Zonder gevolg" />
                                  <FormControlLabel value="2" labelPlacement="start" control={<Radio color="primary" />} label="Bijkomende aangifte" />
                                </RadioGroup>                            
                            </FormControl>
                          </Grid>
                          {fielddata.contr_afhand === '2' &&
                            <Grid style={{paddingTop:'30px'}}item sm={6} md={6} lg={6}>
                                {fielddata.contr_bijk_aang.length > 4 &&
                                  <h6 style={{paddingTop:'10px',cursor:'pointer'}} onClick={() => this.GoToLinkJob(fielddata.contr_bijk_aang)}>{fielddata.contr_bijk_aang_txt}</h6>
                                }                          
                                {fielddata.contr_bijk_aang.length < 4 &&
                                  <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.CreateExtAang()}>
                                    Aanmaken job extra aangifte
                                  </Button>
                                }                          

                            </Grid>
                          }
                        </Grid>
                      </div>)
            break;

          case 'dfv_regnummer':          
            veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small style={errstijl} className={errclass}>Registratie nummer</small>
                        <h6>{fielddata[fieldcode]}</h6>
                    </Grid>)
            break;


          case 'regnummer_bis':
          case 'regnummer':

            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
              break;

          case 'refertenum':
            if (fielddata.job_id === "9403f6300d7e04a4d452035300f18b984988c")
            {

                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            labelText={description}
                            lijst='AllJobRegNumAcc'
                            colId={fielddata[fieldcode]}
                            selectcel='link_jobid'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.corr_aang}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)


            }
            else
            {
                veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            }
            break;


          case 'contr_inhoud':

            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid></Grid>)
              break;


          case 'contr_periode':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={4} md={4} lg={4}>
                          <small className="text-muted p-t-30 db">Periode</small>
                        </Grid>
                        <Grid item sm={8} md={8} lg={8}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata.contr_start_date}
                                coltype = 'STR'                        
                                labelText='Startdatum'
                                inputProps={{value:fielddata.contr_start_date}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'contr_start_date',false)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={4} md={4} lg={4}>
                        </Grid>
                        <Grid item sm={8} md={8} lg={8}>
                          <div style={{marginTop: '-4px'}}>
                            <EditDateTime className="card-title m-t-10"
                                value={fielddata.contr_end_date}
                                coltype = 'STR'                        
                                labelText='Einddatum'
                                inputProps={{value:fielddata.contr_end_date}}
                                formControlProps={{fullWidth: true}}
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'contr_end_date',false)}
                                setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>

                      </Grid>)
              break;

          case 'contr_tabaang':
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <small className="text-muted p-t-30 db">Betreft aangifte(s)</small>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6}>
                          <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.GetOpenAang()}>
                            Keuzelijst aangiftes
                          </Button>
                        </Grid>



                        <Grid item sm={12} md={12} lg={12}>
                            {this.state.linkcontraang.map(onkdata => {
                              return (
                                <Chip
                                  style={{marginTop:'5px',marginRight:'5px'}}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  key={GenFunc.MakeId()}
                                  label={onkdata.description}
                                  onClick={() => this.GoToLinkJob(onkdata.id)}
                                  onDelete={() => this.DeleteContrAanvr(onkdata)}

                                />
                              );
                            })}                                  
                        </Grid>
                      </Grid>)
            break;



          case 'periode':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;

          case 'aang_vperiode':
            if (fielddata.aang_optie === '1' )
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                          <div style={{marginTop: '-4px'}}>
                            <EditString className="card-title m-t-10"
                              value={fielddata[fieldcode]}
                              coltype = 'STR'                        
                              labelText={description}
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                              setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                            />
                          </div>
                        </Grid>)
            }
            break;

          case 'jaar':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            format = {[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;

          case 'tarief':
          case 'contr_uren':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'NUM'                        
                            decimal={4}
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;


          case 'description':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText={description}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;



          case 'info1':
          case 'info2':
          case 'info_tab1':
          case 'info_tab2':
          case 'info_tab3':
          case 'info_tab4':
          case 'info_tab5':
          case 'info_tab6':
          case 'info_tab7':
          case 'info_tab8':
          case 'info_tab9':
          case 'info_tab10':
          case 'info_tab11':
          case 'info_tab12':
          case 'info_tab13':
          case 'info_tab14':
          case 'info_tab15':
          case 'info_tab16':
          case 'info_tab17':
          case 'info_tab18':
          case 'info_tab19':
          case 'info_tab20':

          
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <div style={{marginTop: '-4px'}}>
                          <EditString className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            coltype = 'STR'                        
                            labelText='Uitgebreide info'
                            multiline={true}   
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                          />
                        </div>
                      </Grid>)
            break;








          case 'account_name':
            if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small style={errstijl} className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToMain('ACC',fielddata)} style={{cursor:'pointer'}}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            }
            else
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            filter={[{field:'user',value:fielddata.user_id}]}
                            labelText={description}
                            lijst='AllAccounts'
                            colId={fielddata[fieldcode]}
                            selectcel='account_id'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.account_id}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            }
            break;

          case 'corr_aang_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            filter={[{field:'account_id',value:fielddata.account_id},{field:'job_id',value:'c8011e2728d9d4c2f636f067f89cc14862c'},{field:'corr_id',value:''},{field:'contr_id',value:''},{field:'country',value:fielddata.country},]}
                            labelText={description}
                            lijst='AllJobs'
                            colId={fielddata[fieldcode]}
                            selectcel='corr_aang'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.corr_aang}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;

          case 'corr_controle_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            filter={[{field:'account_id',value:fielddata.account_id},{field:'job_id',value:'6f02491822f45568161a8cdf4ad2299f6d23'},{field:'corr_id',value:''},{field:'contr_id',value:''}]}
                            labelText={description}
                            lijst='AllJobs'
                            colId={fielddata[fieldcode]}
                            selectcel='corr_controle'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.corr_controle}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />

                      </Grid>)
            break;





          case 'country_txt':
           if (fielddata.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' )
            {
              veld =  (<Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                      <small style={errstijl} className={errclass}>{description}</small>
                        <h6 onClick={() => this.GoToMain('ACC',fielddata)} style={{cursor:'pointer'}}>{fielddata[fieldcode]}</h6>
                    </Grid>)
            }
            else
            {
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            labelText={description}
                            lijst='AllLanden'
                            colId={fielddata[fieldcode]}
                            selectcel='country'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.country}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            }
            break;

          case 'status_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditSelect className="card-title m-t-10"
                          value={fielddata[fieldcode]}
                          labelText={description}
                          data={this.state.data}
                          type='AllJobStatus'
                          colId='status_txt'
                          selectcel='status'
                          id={fielddata.status}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;

          case 'aang_periode_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditSelect className="card-title m-t-10"
                          value={fielddata[fieldcode]}
                          labelText={description}
                          data={this.state.data}
                          type='AllPeriodes'
                          colId='aang_periode_txt'
                          selectcel='aang_periode'
                          id={fielddata.aang_periode}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;
          case 'user_name':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            labelText={description}
                            lijst='AllUsers'
                            colId={fielddata[fieldcode]}
                            selectcel='user_id'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.user_id}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;

          case 'kantoor_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            filter={[{field:'country',value:fielddata.country}]}
                            labelText={description}
                            lijst='AllKantoren'
                            colId={fielddata[fieldcode]}
                            selectcel='kantoor_id'
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            id={fielddata.kantoor_id}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;

          case 'registratie_txt':
            veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditAutoSelect className="card-title m-t-10"
                            value={fielddata[fieldcode]}
                            filter={[{field:'user_id',value:fielddata.user_id},
                              {field:'land',value:fielddata.country},
                              {field:'btwkant',value:fielddata.kantoor_id},
                              {field:'accountid',value:fielddata.account_id}]}
                            labelText={description}
                            lijst='AllRegistratie'
                            colId={fielddata[fieldcode]}
                            SetObject = {(object,colid) => this.SetObject(object,fieldcode)} 
                            selectcel='registration_id'
                            id={fielddata.registration_id}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                            setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
            break;


          case 'facturatie':
              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={12} md={12} lg={12}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText={description}                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
              break;                     

          case 'forfait':

              veld =  ( <Grid key={'KJA'+fieldcode+this.state.refid} item sm={6} md={6} lg={6}>
                        <EditCheck style={{marginLeft: '-20px'}} className="card-title m-t-10" 
                          value={fielddata[fieldcode]}
                          labelPlacement="start"
                          labelText={description}                          
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,fieldcode,verplicht)}
                          setError={(params,value,type) => this.ErrorCheck(params,fielddata[fieldcode],type,fieldcode,verplicht)}
                        />
                      </Grid>)
              break;                     


          default:    
            veld =  null;
            break;
        } 

      }
      return veld;
    }

    async handleClose() {
//      let overzdata = {data:this.state.data, workflows:this.state.workflows}
//      await SqlFunction.DoSql('ChgJob',overzdata);

        await this.props.dispatch(zetviewleeg(this.props.data.data.description, this.props.data.data.id, 'JOB',null));      

        if (this.props.close) {
            this.props.close()
        }
    };

    async getStories() 
    {
      var stories = [];
      let statdat = this.state.data;
      var teller = 0;
      let storiedata = {}
      let inverted = false
      if (this.props.data.workflows || this.state.workflows)
      {
        let dataworkflows = this.state.workflows ? this.state.workflows : this.props.data.workflows;
        let telindex = 0;
        for (teller = 0; teller < dataworkflows.length; teller++) 
        {
          let data = dataworkflows[teller];
          let voegtoe = true;
          let vdata = (teller > 0) ? dataworkflows[teller - 1] : null;

          if (data.conditie && data.conditie.length > 1)
          {
            voegtoe = eval(data.conditie)
//            vdata.completed = '2';

          }
          if (voegtoe)
          {

            if (vdata)
            {
              if (vdata.conditie && vdata.conditie.length > 1)
              { 
                let chkvdata = eval(vdata.conditie)
                if (!chkvdata)
                {
                  vdata.completed = '2';
                }
              }
            }

            data.index = telindex;
            data.origindex = teller;
            let zetafg = true;
            let result = null;
            result = (data && data.velden) ? data.velden.filter(o=> o.veldcode === 'factnum') : null;
            if (Array.isArray(result))
            {
                if (result.length > 0 && (this.state.data.facturatie === '1' || this.state.data.facturatie === true))
                {
                   zetafg = false
                }
            }

            result = (data && data.velden) ? data.velden.filter(o=> o.veldcode === 'fact_id') : null;
            if (Array.isArray(result))
            {
                if (result.length > 0 && (this.state.data.facturatie === '1' || this.state.data.facturatie === true))
                {
                   zetafg = false
                }
            }
            result = (data && data.velden) ? data.velden.filter(o=> o.veldcode === 'link_jobtxt') : null;
            if (Array.isArray(result))
            {
                if (result.length > 0 && this.state.data.code.includes('VOI')  === false )
                {
                   zetafg = false
                }
            }

            // Nu controle op velden
            {data.velden.map(datavld => {
              if (datavld.verplicht === '1' || datavld.verplicht === true)
              {
                if (!this.state.data[datavld.veldcode])
                {
                  zetafg = false   
                }
                else
                { 
                  if (this.state.data[datavld.veldcode] && this.state.data[datavld.veldcode].length < 1)
                  {
                    zetafg = false   
                  }
                }
              }
              return zetafg;
            })}            
            result = (data && data.velden) ? data.velden.filter(o=> o.veldcode === 'xml_vataang') : null;
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                zetafg = true
              }
            }

            let dattel = teller + 1;
            storiedata = 
            {
              badgeColor:data.completed === true || data.completed === '1' ? 'success' : 'primary',
              inverted:inverted,
              completed:data.completed,
              id:data.id,
              jobid:data.job_id,
              badgeIcon: telindex + 1,
              index:telindex,
              origindex:teller,
              volgorde:data.volgorde,
              title:data.title,
              velden:data.velden,
              description:data.description,
              badgeClick: this.WorkFlowClick,
              bestanden:data.bestanden,
              titleColor: "primary",
              body: (
                <div onDrop={(event) => this.WorkFlowEnter(event,data)}  >
                  <Grid container spacing={1}>
                      {data.velden.map(datavld => {
                          let hdatavld = datavld;
                          
                          if (datavld.veldcode.indexOf("info_tab") >= 0)
                          {
                              hdatavld.veldcode = 'info_tab'+dattel;
                          }
                          return this.getFields(hdatavld.veldcode,hdatavld.description,hdatavld.verplicht,false)
                      })}                                  
                  </Grid>
                  {(data.bestanden === '1' || data.bestanden === true) &&
                    <div>
                      <div className='div_line' style={{marginTop: '25px',width:'100%'}}></div>
                      <Grid container spacing={1}>
                        <Grid item sm={8} md={8} lg={8}>
                          <h4 tag="h4" className="mt-3">Documenten</h4>  
                        </Grid>
                        <Grid item sm={4} md={4} lg={4}>
                          <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '1px'}}>
                            <IconButton onClick={() => this.AddDocument(data)} color="primary"component="span">
                              <AddIcon />
                            </IconButton>                                    
                          </div>
                        </Grid>
                        <div className='div_line' style={{marginBottom: '20px',marginTop: '2px',width:'100%'}}></div>
                        <Grid item sm={12} md={12} lg={12}>
                          {data.documenten.map(docdata => {
                            return (
                              <Chip
                                style={{marginTop:'5px',marginRight:'5px'}}
                                variant="outlined"
                                color="primary"
                                size="small"
                                key={GenFunc.MakeId()}
                                label={docdata.name}
                                onClick={() => this.ClickDocument(docdata)}
                                onDelete={() => this.DeleteDocument(docdata,data)}
                              />
                            );
                          })}                                  
                        </Grid>
                        <div className='div_line' style={{marginBottom: '25px',marginTop: '25px'}}></div>
                      </Grid>
                    </div>}

                </div>
              ),
              footer: (
                <div onDrop={(event) => this.WorkFlowEnter(event,data)}  >
                  {data.completed !== true && data.completed !== '1' && (!vdata || (vdata.completed === '1' || vdata.completed === '2' || vdata.completed === true)) && zetafg === true && data.title !== "Facturatie" &&
                  <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>

                    <Button disabled={save} style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleAfhandel(data)}>
                      Afgehandeld
                    </Button>
                  </div>}
                  {(data.completed === true || data.completed === '1') && 
                    <Grid container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                        <small className="text-muted p-t-30 db">Datum afhandeling</small>
                        <h6>{data.datum}</h6>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <small className="text-muted p-t-30 db">Gebruiker afhandeling</small>
                        <h6>{data.user_name}</h6>
                      </Grid>
                    </Grid>}

                </div>
              )
            }
            telindex = telindex + 1;
            stories.push(storiedata);
            inverted = !inverted;
          }
          else
          {
            if (data.completed === '0' || data.completed === 0 || data.completed === false)
            {
              let wardata = this.state.workflows; 
              let wfdata = wardata[teller];
              wfdata.userid = this.props.user.userData.id;
              wfdata.datum = GenFunc.Get_Vandaag();
              wfdata.completed = '2';
              wardata[teller] = wfdata;
              let overzdata = {data:this.state.data, workflows:wardata}
              await SqlFunction.DoSql('ChgJob',overzdata);
            }
          }
        }
      }
      this.setState({workflowstories:stories,changewfindex:null})   
    }  

    async getNewStories(workflows) 
    {
      var stories = [];
      var teller = 0;
      let storiedata = {}
      let inverted = false
      if (workflows)
      {
        for (teller = 0; teller < workflows.length; teller++) 
        {
          let data = workflows[teller];
          let dattel = teller + 1;
          storiedata = 
          {
            badgeColor:data.completed === true || data.completed === '1' ? 'success' : 'primary',
            inverted:inverted,
            completed:data.completed,
            id:data.id,
            jobid:data.job_id,
            badgeIcon: data.completed === true || data.completed === '1' ? (<Check/>) : data.volgorde,
            index:teller,
            volgorde:data.volgorde,
            title:data.title,
            velden:data.velden,
            bestanden:data.bestanden,
            description:data.description,
            badgeClick: this.WorkFlowClick,
            titleColor: "primary",
            body: (
              <div>
                <Grid container spacing={1}>
                    {data.velden.map(datavld => {
                      let hdatavld = datavld;
                      if (datavld.veldcode.indexOf("info_tab") >= 0)
                      {
                          hdatavld.veldcode = 'info_tab'+dattel;
                      }
                      return this.getFields(hdatavld.veldcode,hdatavld.description,hdatavld.verplicht,true)
                    })}                                  
                </Grid>
              </div>
            ),
            footer: (
              <div>
              </div>
            )
          }
          stories.push(storiedata);
          inverted = !inverted;
        }
      }
      await this.setState({workflowstories:stories})   
      this.WorkFlowClickverder(this.state.changewfindex,true)

    }  

    async CreateExtAang()    
    {
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      let data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.state.data.account_id;
        data.user_id = this.state.data.user_id;
        data.country = this.state.data.country
        data.create_date = GenFunc.Get_Vandaag();
        data.start_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = null;
        data.code = "CORR"
        data.job_id = "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3";
        data.contr_aang = this.state.data.id;
        data.description = 'Bijkomende aangifte '+this.state.data.description;
      let upddata = {data:data,workflows:[]}
      let terdata = await SqlFunction.DoSql('ChgJob',upddata);
      data = this.state.data;
      data.contr_bijk_aang = terdata.id;
      upddata = {data:data, workflows:this.state.workflows};
      await SqlFunction.DoSql('ChgJob',upddata);
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      rowpinnend = null;
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten, linkcontraang: jobdata.linkcontraang, douaneskol:jobdata.douaneskol})          
      this.getStories();
      this.GoToLinkJob(terdata.id)


      // nu updaten hoofddata

    }

    async handlePdfScanVat(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      document.getElementById(PdfScanVat).submit();

//      let terdata = await SqlFunction.DoSql('MakeVatScanPdf',this.state.data);
      // Nu ophalen dfv tabel
//      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handlePdfListing(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }


      document.getElementById(PdfListing).submit();
      // Nu ophalen dfv tabel
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handlePdfListingKlant(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }


      document.getElementById(PdfListingKlant).submit();
      // Nu ophalen dfv tabel
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }


    async handleXmlListing(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }


      document.getElementById(XmlListing).submit();

      // Nu ophalen dfv tabel
//      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleDfvImport(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      await SqlFunction.DoSql('CreaDfvData',this.state.data);
      // Nu ophalen dfv tabel
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes,  dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleOnkListing(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }

      var elem = document.getElementById(PdfOnkListingName);
      if (elem) {elem.value = this.state.data.id}
      document.getElementById(PdfOnkListing).submit();
      // Nu ophalen dfv tabel
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleDfvDoc(){
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      await SqlFunction.DoSql('CreaDfv',this.state.data);
      // Nu ophalen dfv tabel
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);

      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes,  dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleJvlImport(){
      // Bewaren gegevens
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      await SqlFunction.DoSql('CreaJvlData',this.state.data);
      // Nu ophalen dfv tabel
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes,  dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol, jvldata: jobdata.jvldata, jvlkol: jobdata.jvlkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleJvlDoc(){
      // Bewaren gegevens
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      await SqlFunction.DoSql('CreaJvl',this.state.data);
      // Nu ophalen dfv tabel
      let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);

      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes,  dfvdatalijst:jobdata.dfvdatalijst, dfvdata: jobdata.dfvdata, dfvkol: jobdata.dfvkol,jvldata: jobdata.jvldata, jvlkol: jobdata.jvlkol, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,
             douaneskol:jobdata.douaneskol,PopUpScreen:null})          
      this.WorkFlowClickverder(this.state.changewfindex,true)
    }

    async handleSave(){
      // Bewaren gegevens
      if (this.props.data && this.props.data.data && this.props.data.data.onhold === '1')
      {
          return;
      }
      let hdata = this.state.data
      let data = {data:hdata, workflows:this.state.workflows}
      let terdata = data.data
      var i = 0;
      var row = null
      terdata = await SqlFunction.DoSql('ChgJob',data);
      if (rowpinnend && rowpinnend.save === true && rowpinnend.soort === 'ONKOSTEN' )
      {
        rowpinnend.job_id = this.state.data.id;
        rowpinnend.verwijder = '2';
        await SqlFunction.DoSql('ChgJobOnkosten',rowpinnend);
        rowpinnend = null;
      }

      if (rowpinnend && rowpinnend.save === true && rowpinnend.soort === 'BOETES' )
      {
        rowpinnend.job_id = this.state.data.id;
        rowpinnend.verwijder = '2';
        await SqlFunction.DoSql('ChgJobBoetes',rowpinnend);
        rowpinnend = null;
      }

      if (rowpinnend && rowpinnend.save === true && rowpinnend.soort === 'DOUANES' )
      {
        rowpinnend.job_id = this.state.data.id;
        rowpinnend.verwijder = '2';
        await SqlFunction.DoSql('ChgJobDouanes',rowpinnend);
        rowpinnend = null;
      }

      if (this.state.onkosten)
      {
        for (i = 0; i < this.state.onkosten.length; i++) 
        {
          row = this.state.onkosten[i];
          if (row.save === true)
          {
            await SqlFunction.DoSql('ChgJobOnkosten',row);
          }
        }
      }
      if (this.state.douanes)
      {
        for (i = 0; i < this.state.douanes.length; i++) 
        {
          row = this.state.douanes[i];
          if (row.save === true)
          {
            await SqlFunction.DoSql('ChgJobDouanes',row);
          }
        }
      }
      if (this.state.boetes)
      {
        for (i = 0; i < this.state.boetes.length; i++) 
        {
          row = this.state.boetes[i];
          if (row.save === true)
          {
            await SqlFunction.DoSql('ChgJobBoetes',row);
          }
        }
      }



      let jobdata = await SqlFunction.DoSql('GetJob',terdata);
      rowpinnend = null;
      data.data = jobdata.data;
      data.workflows = jobdata.workflows;
      await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten, linkcontraang: jobdata.linkcontraang, douaneskol:jobdata.douaneskol})          
      this.getStories()
      if (hdata.terug_actie === '4' && (!hdata.correctie || (hdata.correctie && hdata.correctie.length < 4)))
      {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Correctie job maken voor deze aangifte ?</h1>
                    <button
                      onClick={async () => {
                        hdata.creacorrectie = true;
                        data = {data:hdata, workflows:this.state.workflows}
                        terdata = data.data
                        terdata = await SqlFunction.DoSql('ChgJob',data);
                        jobdata = await SqlFunction.DoSql('GetJob',terdata);
                        data.data = jobdata.data;
                        data.workflows = jobdata.workflows;
                        await this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol
                          ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten, linkcontraang: jobdata.linkcontraang, douaneskol:jobdata.douaneskol})          
                        this.getStories()
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            

      }


      if (jobdata.data.crea_aang === '11111111')
      {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Job(s) Aangifte maken voor registratie ?</h1>
                    <button
                      onClick={() => {
                        this.handleJobRegJa();
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            

      }

    }

    SaveCheck(data) 
    {
      let save = false;
      if (this.state.changewfindex !== null && this.state.workflowstories[this.state.changewfindex] && this.state.workflowstories[this.state.changewfindex].velden)
      {
        let fielddata = this.state.workflowstories[this.state.changewfindex].velden;
        for (var i = 0; i < fielddata.length; i++) 
        {
          let item = fielddata[i];
          if (item.verplicht === true ||item.verplicht === '1')
          {
            if (data[item.veldcode] !== undefined && data[item.veldcode] !== null)
            {
              if(data[item.veldcode].length < 1) 
              {
                save = true
              }
            }
          }
        }      
      }
      return save
    }

    async ErrorCheck(that,waarde,type,colId,verplicht) 
    {
      let terug = false;
      if (verplicht === '1' || verplicht === true)
      {
        if(waarde && waarde.length > 1) 
        {
          terug = false;            
        }
        else
        {
          terug = true;            
        }
      }
      if (typeof that.setState === "function") 
      {
          that.setState({error:terug})
      }
    }  

    async ValueCheck(that,waarde,type,colId,verplicht) 
    {
      let terug = false;
      let data = this.state.data;
      let koers = parseFloat(data.koers)
      save = this.state.save;
      var registfilter = [];
      var accountfilter = [];
      var kantoorfilter = [];
      var corraangfilter = [];
      var corrcontrolefilter = [];
      let terug_bedr = 0
      let terug_bedr_val = 0
      let wbedrag = 0
      let saldo = 0

      if (verplicht === '1' || verplicht === true)
      {
        if(waarde && waarde.length > 1) 
        {
          terug = false;            
        }
        else
        {
          if (waarde && waarde.id)
          {
            if(waarde.id.length > 1) 
            {
              terug = false;            
            }
            else
            {
               terug = true
            }
          }
          else
          {
              terug = true;            
          }
        }
      }

      switch (colId) 
      {

       case 'vat_overname':
       if (waarde === true)
       {
          if (this.state.vatrefund && this.state.vatrefund.length < 1)
          {
            let filldata = this.state.data;
            filldata.workflows = this.state.workflows;
            await SqlFunction.DoSql('FillJobVat',this.state.data);

            let jobdata = await SqlFunction.DoSql('GetJob',this.state.data);
            this.setState({data:jobdata.data,workflows:jobdata.workflows,onkosten:jobdata.onkosten, onkostenkol:jobdata.onkostenkol,vatrefund:jobdata.vatrefund, vatrefundkol:jobdata.vatrefundkol
            ,boetes:jobdata.boetes, boeteskol:jobdata.boeteskol, dfvdatalijst:jobdata.dfvdatalijst, dfvdata:jobdata.dfvdata, dfvkol:jobdata.dfvkol,jvldata:jobdata.jvldata, jvlkol:jobdata.jvlkol,douanes:jobdata.douanes,linkonkosten: jobdata.linkonkosten,linkcontraang: jobdata.linkcontraang,douaneskol:jobdata.douaneskol})          
            this.WorkFlowClickverder(this.state.changewfindex,true)
            data.vat_overname = '1';
          }
          else
          {
            data.vat_overname = '0';
          }

       }
        break;

       case 'contr_afhand':
          data.contr_afhand = that.target.value;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;

       case 'jaar':
           data.jaar = waarde;
           let arperiode = data.periode.split('/');
           if (arperiode.length > 1)
           {
               data.periode = arperiode[0]+'/'+data.jaar;
           }
           else
           {
                data.periode = data.jaar
           }
          this.WorkFlowClickverder(this.state.changewfindex,true)
           break;

       case 'dfv_periodes':
           data.dfv_periodes = waarde;
           let chdata = {periodes:waarde,totaal:data.tot_dfv}
           let calcdata = await SqlFunction.DoSql('CalcDfv',chdata);
           if (calcdata)
           {
              if (calcdata.sub_dfv_na) {data.sub_dfv_na = calcdata.sub_dfv_na }
              if (calcdata.sub_dfv_af) {data.sub_dfv_af = calcdata.sub_dfv_af }
              if (calcdata.tdfv_na) {data.tdfv_na = calcdata.tdfv_na }
              if (calcdata.tdfv_af) {data.tdfv_af = calcdata.tdfv_af }
           }
            break;

       case 'aang_optie':
          data.aang_optie = that.target.value;
          await this.GetTarief(data);
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;


          
       case 'vat_factextra':
          data.vat_factextra = waarde;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;


       case 'terug_optie':

          data.terug_optie = that.target.value;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;

       case 'def_beslis_aanv':

          data.def_beslis_aanv = that.target.value;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;



        case 'kz_nihil':

          data.kz_nihil = that.target.value;
          this.WorkFlowClickverder(this.state.changewfindex,true)

          break;

        case 'corr_door':
          data.corr_door = that.target.value;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          break;



        case 'periode':
          data.periode = waarde;
          break;


        case 'terug_bedr':
          data[colId] = waarde;
          terug_bedr = parseFloat(data.terug_bedr)
          if (isNaN(terug_bedr)) {terug_bedr = 0}
          data['terug_bedr_val'] = terug_bedr * koers
          data['vat_basisfact'] = terug_bedr
          data['vat_basisfact_val'] = terug_bedr * koers

          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }
          break;


        case 'terug_bedr_val':
          data[colId] = waarde;
          terug_bedr_val = parseFloat(data.terug_bedr_val)
          if (isNaN(terug_bedr_val)) {terug_bedr_val = 0}
          data['terug_bedr'] = terug_bedr_val / koers
          data['vat_basisfact'] = terug_bedr_val / koers
          data['vat_basisfact_val'] = terug_bedr_val
          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }
          break;


        case 'corr_omzbel':
        case 'corr_voorbel':
          data[colId] = waarde;
          let omzetbel = parseFloat(data.omzetbel)
          let voorbel = parseFloat(data.voorbel)
          let saldotebet = parseFloat(data.saldotebet)

          let corr_omzbel = parseFloat(data.corr_omzbel)
          let corr_verwijl = parseFloat(data.corr_verwijl)
          let corr_voorbel = parseFloat(data.corr_voorbel)
          let corr_tebet = parseFloat(data.corr_tebet)

          if (isNaN(omzetbel)) {omzetbel = 0}
          if (isNaN(voorbel)) {voorbel = 0}
          if (isNaN(saldotebet)) {saldotebet = 0}
          if (isNaN(corr_omzbel)) {corr_omzbel = 0}
          if (isNaN(corr_verwijl)) {corr_verwijl = 0}
          if (isNaN(corr_voorbel)) {corr_voorbel = 0}
          if (isNaN(corr_tebet)) {corr_tebet = 0}



          let gecorr_omzetbel = omzetbel - corr_omzbel
          let gecorr_voorbel = voorbel - corr_voorbel
          let corr_tebetaal = corr_omzbel - corr_voorbel
          let gecorr_tebet = saldotebet - corr_tebet          

          data.corr_tebet = (gecorr_omzetbel - gecorr_voorbel) *-1
          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;

        case 'omzetbel':

          if (data.job_id === '4501c498cce2e2d7fbdea1afc51c7c6ad26' && data.kz_nihil === '2')
          {
            wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(wbedrag)) {wbedrag = 0}
            data.voorbel = wbedrag * 11;
            data.omzetbel = wbedrag;

            if (type === 'ENTER')
            {
              this.WorkFlowClickverder(this.state.changewfindex,true)
            }

          }
          else
          {
            wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(wbedrag)) {wbedrag = 0}
            saldo = wbedrag - (data.voorbel + data.tot_link_douane + data.tot_link_boetes + data.voorafbetaling);
            data.omzetbel = wbedrag;
            data.saldotebet = saldo  

            data.omzetbel_val = wbedrag * data.koers;
            data.saldotebet_val = saldo * data.koers  

            if (type === 'ENTER')
            {
              this.WorkFlowClickverder(this.state.changewfindex,true)
            }
          }
 
          break;


        case 'omzetbel_val':
          wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0} else {wbedrag = wbedrag / data.koers}
          saldo = wbedrag - (data.voorbel + data.tot_link_douane + data.tot_link_boetes + data.voorafbetaling);

          data.omzetbel = wbedrag;
          data.saldotebet = saldo  
          data.omzetbel_val = wbedrag * data.koers;
          data.saldotebet_val = saldo * data.koers  

          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;



        case 'dir_mvh':
          wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0}
          data.dir_mvh = wbedrag;
          data.dir_mvh_val = wbedrag * data.koers;
          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;


        case 'dir_mvh_val':
          wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0} else {wbedrag = wbedrag / data.koers}
          data.dir_mvh = wbedrag;
          data.dir_mvh_val = wbedrag * data.koers;
          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;

        case 'voorafbetaling':
          wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0}
          data.voorafbetaling = wbedrag;
          data.voorafbetaling_val = wbedrag * data.koers;

          saldo = data.omzetbel - (data.voorbel + data.tot_link_douane + data.tot_link_boetes + wbedrag);
          data.saldotebet = saldo  
          data.saldotebet_val = saldo * data.koers  


          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;


        case 'voorafbetaling_val':
          wbedrag = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(wbedrag)) {wbedrag = 0} else {wbedrag = wbedrag / data.koers}
          data.voorafbetaling = wbedrag;

          saldo = data.omzetbel - (data.voorbel + data.tot_link_douane + data.tot_link_boetes + wbedrag);
          data.saldotebet = saldo  
          data.saldotebet_val = saldo * data.koers  

          data.voorafbetaling_val = wbedrag * data.koers;
          if (type === 'ENTER')
          {
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }

          break;




        case 'status_txt':
        case 'status':
          if (waarde.id && waarde.text)
          {        
            data.status_txt = waarde.text;
            data.status = waarde.id;
          }
          break;

        case 'terug_actie_txt':
        case 'terug_actie':
          if (waarde.id && waarde.text)
          {        
            data.terug_actie_txt = waarde.text;
            data.terug_actie = waarde.id;
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }
          break;


        case 'bankid':
        case 'banktxt':        
          if (waarde.id && waarde.text)
          {        
            data.banktxt = waarde.text;
            data.bankid = waarde.id;
            data.bank_bic = waarde.bic;
            data.bank_iban = waarde.reknummer;
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }
          break;


          

        case 'per1':
          if (waarde === true) {
            data.per1 = '1'
          }
          if (waarde === false) {
            data.per1 = '0'
          }
          break;
        case 'per2':
          if (waarde === true) {
            data.per2 = '1'
          }
          if (waarde === false) {
            data.per2 = '0'
          }
          break;
        case 'per3':
          if (waarde === true) {
            data.per3 = '1'
          }
          if (waarde === false) {
            data.per3 = '0'
          }
          break;
        case 'per4':
          if (waarde === true) {
            data.per4 = '1'
          }
          if (waarde === false) {
            data.per4 = '0'
          }
          break;
        case 'per5':
          if (waarde === true) {
            data.per5 = '1'
          }
          if (waarde === false) {
            data.per5 = '0'
          }
          break;
        case 'per6':
          if (waarde === true) {
            data.per6 = '1'
          }
          if (waarde === false) {
            data.per6 = '0'
          }
          break;
        case 'per7':
          if (waarde === true) {
            data.per7 = '1'
          }
          if (waarde === false) {
            data.per7 = '0'
          }
          break;
        case 'per8':
          if (waarde === true) {
            data.per8 = '1'
          }
          if (waarde === false) {
            data.per8 = '0'
          }
          break;
        case 'per9':
          if (waarde === true) {
            data.per9 = '1'
          }
          if (waarde === false) {
            data.per9 = '0'
          }
          break;
        case 'per10':
          if (waarde === true) {
            data.per10 = '1'
          }
          if (waarde === false) {
            data.per10 = '0'
          }
          break;
        case 'per11':
          if (waarde === true) {
            data.per11 = '1'
          }
          if (waarde === false) {
            data.per11 = '0'
          }
          break;
        case 'per12':
          if (waarde === true) {
            data.per12 = '1'
          }
          if (waarde === false) {
            data.per12 = '0'
          }
          break;

        case 'aang_periode_txt':
        case 'aang_periode':
          if (waarde.id && waarde.text)
          {
            data.aang_periode_txt = waarde.text;
            data.aang_periode = waarde.id;
            data.aang_periode_code = waarde.code;
          }

          break;


        case 'job_txt':
        case 'job_id':
          if (waarde.workflows)
          {
            data.job_txt = waarde.text;
            data.job_id = waarde.id;
            data.code = waarde.code;
            await this.GetTarief(data);            
            this.setState({workflows:waarde.workflows})
            this.getNewStories(waarde.workflows);
          }
          break;



        case 'start_date':
        case 'start_date_new':
        case 'end_date':
        case 'corr_dat_correctie':
        case 'corr_dat_ind_correctie':
        case 'contr_end_date':
        case 'contr_start_date':
        case 'dat_toek_aanv':

          data[colId] = waarde;

          if(waarde && waarde.length > 0) 
          {
            var utc = moment(waarde, "DD-MM-YYYY", true)
            if(!utc.isValid()) 
            {
              terug = true
            }
          }
          else
          {
            if (verplicht === '1' || verplicht === true)
            {
               terug = true;
            }
          }
          break;


        case 'refertenum':
          if (data.job_id === "9403f6300d7e04a4d452035300f18b984988c")
          {
              terug = false;

              data.refertenum = waarde.value;
              data.link_jobid = waarde.id;
              data.link_jobtxt = waarde.value;
          }
          else
          {
              data[colId] = waarde;
          }
          break;



        case 'account_name':
        case 'account_id':

            if (data.account_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  if (that.overzdata.onhold === true || that.overzdata.onhold === '1')
                  {
                    terug = true; 
                    data.account_name = '';
                    data.account_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.corr_aang = '';
                    data.corr_aang_txt = '';

                    if (accountobj)
                    {
                      accountobj.SetWaarde("","")
                    }

                    if (regisobj)
                    {
                      regisobj.SetWaarde("","")
                    }
                    if (corraangobj)
                    {
                      corraangfilter.push({field:'job_id',value:'c8011e2728d9d4c2f636f067f89cc14862c'}); 
                      corraangfilter.push({field:'account_id',value:waarde.id}); 
                      corraangfilter.push({field:'corr_id',value:''}); 
                      corraangfilter.push({field:'contr_id',value:''}); 
                      corraangfilter.push({field:'country',value:data.country}); 
                      corraangobj.SetFilter(corraangfilter)
                      corraangobj.SetWaarde("","")
                      data.corr_aang = '';
                      data.corr_aang_txt = '';

                    }
                    if (corrcontroleobj)
                    {
                      corrcontroleobj.SetWaarde("","")
                    }

                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                    }
                    if (data.country && data.country.length > 4)
                    {
                      kantoorfilter.push({field:'country',value:data.country})
                    }
                    if (userobj)
                    {
                      userobj.SetWaarde(that.overzdata.user,that.overzdata.user_name)
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                    if (regisobj)
                    {
                      regisobj.SetFilter(registfilter)
                    }
                  }
                  else
                  {
                    data.account_name = waarde.value;
                    data.account_id = waarde.id;
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.user_id = that.overzdata.user;
                    data.user_name = that.overzdata.user_name;
                    data.refertenum = '';
                    data.link_jobid = '';
                    data.link_jobtxt = '';

                    if (regisobj)
                    {
                      registfilter.push({field:'accountid',value:waarde.id}); 
                      regisobj.SetWaarde("","")
                    }
                    if (refertenumobj)
                    {
                      await this.props.dispatch(AllJobRegNumAcc(this.props,waarde.id,data.country));
                      refertenumobj.loaddata()
                    }

                    if (corraangobj)
                    {
                      corraangfilter.push({field:'account_id',value:waarde.id}); 
                      corraangfilter.push({field:'job_id',value:'c8011e2728d9d4c2f636f067f89cc14862c'}); 
                      corraangfilter.push({field:'corr_id',value:''}); 
                      corraangfilter.push({field:'contr_id',value:''}); 
                      corraangfilter.push({field:'country',value:data.country}); 
                      data.corr_aang = '';
                      data.corr_aang_txt = '';
                      corraangobj.SetWaarde("","")
                      corraangobj.SetFilter(corraangfilter)
                    }
                    if (corrcontroleobj)
                    {
                      corrcontrolefilter.push({field:'account_id',value:waarde.id}); 
                      corrcontrolefilter.push({field:'job_id',value:'6f02491822f45568161a8cdf4ad2299f6d23'}); 
                      corrcontrolefilter.push({field:'corr_id',value:''}); 
                      corrcontrolefilter.push({field:'contr_id',value:''}); 
                      corrcontroleobj.SetWaarde("","")
                      corrcontroleobj.SetFilter(corrcontrolefilter)
                    }


                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                    }
                    if (data.country && data.country.length > 4)
                    {
                      kantoorfilter.push({field:'country',value:data.country})
                    }
                    if (userobj)
                    {
                      userobj.SetWaarde(that.overzdata.user,that.overzdata.user_name)
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                    if (regisobj)
                    {
                      regisobj.SetFilter(registfilter)
                    }
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.account_name = '';
                    data.account_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    accountobj.SetWaarde('','')
                   
                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                    }
                    if (data.country && data.country.length > 4)
                    {
                      kantoorfilter.push({field:'country',value:data.country})
                    }
                    if (regisobj)
                    {
                      regisobj.SetFilter(registfilter)
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                  }
                }
                await this.GetTarief(data);
                this.WorkFlowClickverder(this.state.changewfindex,true)

            }
            if (type === 'DIDMOUNT')
            {
                registfilter.push({field:'accountid',value:data.account_id}); 
                if (data.country && data.country.length > 4)
                {
                  registfilter.push({field:'land',value:data.country})
                }
                if (data.country && data.country.length > 4)
                {
                  kantoorfilter.push({field:'country',value:data.country})
                }
                if (regisobj)
                {
                  regisobj.SetFilter(registfilter)
                }
                if (kantoorobj)
                {
                  kantoorobj.SetFilter(kantoorfilter)
                }
            }     
            if (verplicht === '1' || verplicht === true)
            {
              if(waarde && waarde.id && waarde.id.length > 2) 
              {
                terug = false;            
              }
              else
              {
                terug = true;            
              }
            }
            break;

        case 'registratie_txt':
        case 'registration_id':

            if (data.registration_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.registratie_txt = waarde.value;
                  data.registration_id = waarde.id;
                  data.account_name = that.overzdata.account_name;
                  data.account_id = that.overzdata.accountid;
                  data.kantoor_txt = that.overzdata.btwkant_txt;
                  data.kantoor_id = that.overzdata.btwkant;
                  data.user_id = that.overzdata.user_id;
                  data.user_name = that.overzdata.user_name;
                  data.country = that.overzdata.land;
                  data.country_txt = that.overzdata.land_txt;
                  if (userobj)
                  {
                    userobj.SetWaarde(that.overzdata.user_id,that.overzdata.user_name)
                  }
                  if (accountobj)
                  {
                    accountobj.SetWaarde(that.overzdata.accountid,that.overzdata.account_name)
                    if (that.overzdata.accountid && that.overzdata.accountid.length > 4)
                    {
                      registfilter.push({field:'accountid',value:that.overzdata.accountid})
                    }
                  }
                  if (kantoorobj)
                  {
                    kantoorobj.SetWaarde(that.overzdata.btwkant,that.overzdata.btwkant_txt)
                    if (that.overzdata.land && that.overzdata.land.length > 2)
                    {
                      kantoorfilter.push({field:'country',value:that.overzdata.land})
                    }
                  }
                  if (countryobj)
                  {
                    countryobj.SetWaarde(that.overzdata.land,that.overzdata.land_txt)
                  }
                  if (regisobj)
                  {
                    regisobj.SetFilter(registfilter)
                  }
                  if (kantoorobj)
                  {
                    kantoorobj.SetFilter(kantoorfilter)
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.registratie_txt = '';
                    data.registration_id = '';
                    regisobj.SetWaarde('','')
                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                    }
                    if (data.account_id && data.account_id.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.accountid})
                    }
                    if (data.country && data.country.length > 4)
                    {
                      kantoorfilter.push({field:'country',value:data.country})
                    }

                    if (regisobj)
                    {
                      regisobj.SetFilter(registfilter)
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                  }
                }
                this.WorkFlowClickverder(this.state.changewfindex,true)
            }

            if (data.account_id && type === 'DIDMOUNT')
            {
                if (regisobj)
                {
                  registfilter.push({field:'accountid',value:data.accountid})
                }
                if (kantoorobj)
                {
                  kantoorfilter.push({field:'country',value:data.country})
                }

            }
            break;

            
        case 'country_txt':
        case 'country':
            if (data.country !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.country_txt = waarde.value;
                  data.country = waarde.id;
                  data.kantoor_txt = '';
                  data.kantoor_id = '';
                  data.registration_id = '';
                  data.registratie_txt = '';
                  data.link_jobid = '';
                  data.link_jobtxt = '';
                  data.refertenum = '';
                  terug = false;
                  if (regisobj)
                  {
                    regisobj.SetWaarde('','')
                    registfilter.push({field:'land',value:waarde.id})
                    if (data.account_id && data.account_id.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.account_id})
                    }
                    if (data.user_id && data.user_id.length > 4)
                    {
                      registfilter.push({field:'user_id',value:data.user_id})
                    }
                    if (data.kantoor_id && data.kantoor_id.length > 4)
                    {
                      registfilter.push({field:'btwkant',value:data.kantoor_id})
                    }
                  }
                  if (corraangobj)
                  {
                      corraangfilter.push({field:'job_id',value:'c8011e2728d9d4c2f636f067f89cc14862c'}); 
                      corraangfilter.push({field:'country',value:waarde.id}); 
                      corraangfilter.push({field:'account_id',value:data.account_id}); 
                      data.corr_aang = '';
                      data.corr_aang_txt = '';
                      corraangobj.SetWaarde("","")
                      corraangobj.SetFilter(corraangfilter)
                  }

                  if (refertenumobj)
                  {
                    await this.props.dispatch(AllJobRegNumAcc(this.props,data.account_id,waarde.id));
                    refertenumobj.loaddata()
                  }

                  if (kantoorobj)
                  {
                    kantoorobj.SetWaarde('','')
                    kantoorfilter.push({field:'country',value:waarde.id})
                  }
                  if (regisobj)
                  {
                    regisobj.SetFilter(registfilter)
                  }
                  if (kantoorobj)
                  {
                    kantoorobj.SetFilter(kantoorfilter)
                  }
                  await this.GetTarief(data);                  
                  this.WorkFlowClickverder(this.state.changewfindex,true)
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.country_txt = '';
                    data.country = '';
                    countryobj.SetWaarde('','')
                    if (data.account_id && data.account_id.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.account_id})
                    }
                    if (data.kantoor_id && data.kantoor_id.length > 4)
                    {
                      registfilter.push({field:'btwkant',value:data.kantoor_id})
                    }
                    if (corraangobj)
                    {
                      corraangfilter.push({field:'job_id',value:'c8011e2728d9d4c2f636f067f89cc14862c'}); 
                      if (data.account_id && data.account_id.length > 4)
                      {
                        corraangfilter.push({field:'account_id',value:data.account_id}); 
                      }
                      corraangfilter.push({field:'corr_id',value:''}); 
                      corraangfilter.push({field:'contr_id',value:''}); 
                      corraangobj.SetFilter(corraangfilter)
                      data.corr_aang = '';
                      data.corr_aang_txt = '';
                      corraangobj.SetWaarde("","")
                    }
                    if (regisobj)
                    {

                      regisobj.SetFilter(registfilter)
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                    await this.GetTarief(data);                    
                  }
                }
            }

            if (data.country && type === 'DIDMOUNT')
            {
                if (regisobj)
                {
                  registfilter.push({field:'land',value:data.country})
                  if (data.account_id && data.account_id.length > 4)
                  {
                    registfilter.push({field:'accountid',value:data.account_id})
                  }
                  if (data.kantoor_id && data.kantoor_id.length > 4)
                  {
                    registfilter.push({field:'btwkant',value:data.kantoor_id})
                  }
                }
                if (kantoorobj)
                {
                  kantoorfilter.push({field:'country',value:data.country})
                }
                if (regisobj)
                {
                  regisobj.SetFilter(registfilter)
                }
                if (kantoorobj)
                {
                  kantoorobj.SetFilter(kantoorfilter)
                }
            }
            break;


        case 'corr_aang_txt':
        case 'corr_aang':
            if (data.corr_aang !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.corr_aang_txt = waarde.value;
                  data.corr_aang = waarde.id;
                  data.country = that.overzdata.country;
                  data.corr_controle = '';
                  data.corr_controle_txt = '';
                  data.aang_periode = that.overzdata.aang_periode;
                  data.aang_periode_code = that.overzdata.aang_periode_code;
                  data.aang_periode_txt = that.overzdata.aang_periode_txt;
                  data.country = that.overzdata.country;
                  data.country_txt = that.overzdata.country_txt;
                  data.jaar = that.overzdata.jaar;
                  data.omzetbel = that.overzdata.omzetbel;
                  data.periode = that.overzdata.periode;
                  data.voorbel = that.overzdata.voorbel;
                  data.saldotebet = that.overzdata.saldotebet;
                  data.kantoor_txt = that.overzdata.kantoor_txt;
                  data.kantoor_id = that.overzdata.kantoor_id;
                  data.user_id = that.overzdata.user_id;
                  data.user_name = that.overzdata.user_name;
                  await this.GetTarief(data);
                  this.WorkFlowClickverder(1,true,true)
                  this.WorkFlowClickverder(this.state.changewfindex,true)
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.corr_aang_txt = '';
                    data.corr_aang = '';
                    if (corraangobj)
                    {
                      corraangobj.SetWaarde('','')
                    }
                    if (corrcontroleobj)
                    {
                      corrcontroleobj.SetWaarde('','')
                    }                    
                    data.country = '';
                    data.country_txt = '';
                    data.jaar = '';
                    data.omzetbel = 0;
                    data.periode = '';
                    data.voorbel = 0;
                    data.saldotebet = 0;
                    data.kantoor_txt = '';
                    data.kantoor_id = '';
                    await this.GetTarief(data);                    
                    this.WorkFlowClickverder(1,true,true)
                    this.WorkFlowClickverder(this.state.changewfindex,true)
                  }
                }
            }

            break;

        case 'corr_controle_txt':
        case 'corr_controle':
            if (data.corr_controle !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.corr_controle_txt = waarde.value;
                  data.corr_controle = waarde.id;
                  data.corr_aang_txt = '';
                  data.corr_aang = '';
                  data.country = that.overzdata.country;
                  data.aang_periode = that.overzdata.aang_periode;
                  data.aang_periode_code = that.overzdata.aang_periode_code;
                  data.aang_periode_txt = that.overzdata.aang_periode_txt;
                  data.country = that.overzdata.country;
                  data.country_txt = that.overzdata.country_txt;
                  data.jaar = that.overzdata.jaar;
                  data.omzetbel = that.overzdata.omzetbel;
                  data.periode = that.overzdata.periode;
                  data.voorbel = that.overzdata.voorbel;
                  data.saldotebet = that.overzdata.saldotebet;
                  data.kantoor_txt = that.overzdata.kantoor_txt;
                  data.kantoor_id = that.overzdata.kantoor_id;
                  data.user_id = that.overzdata.user_id;
                  data.user_name = that.overzdata.user_name;
                  await this.GetTarief(data);
                  this.WorkFlowClickverder(1,true,true)
                  this.WorkFlowClickverder(this.state.changewfindex,true)
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.corr_controle_txt = '';
                    data.corr_controle = '';
                    if (corraangobj)
                    {
                      corraangobj.SetWaarde('','')
                    }
                    if (corrcontroleobj)
                    {
                      corrcontroleobj.SetWaarde('','')
                    }
                    data.country = '';
                    data.country_txt = '';
                    data.jaar = '';
                    data.omzetbel = 0;
                    data.periode = '';
                    data.voorbel = 0;
                    data.saldotebet = 0;
                    data.kantoor_txt = '';
                    data.kantoor_id = '';
                    await this.GetTarief(data);                    

                    this.WorkFlowClickverder(1,true,true)
                    this.WorkFlowClickverder(this.state.changewfindex,true)
                  }
                }
            }

            break;






        case 'kantoor_txt':
        case 'kantoor_id':
            if (data.kantoor_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.kantoor_txt = waarde.value;
                  data.kantoor_id = waarde.id;
                  data.country = that.overzdata.country;
                  data.country_txt = that.overzdata.country_txt;
                  registfilter.push({field:'btwkant',value:waarde.id})

                  if (data.accountid && data.accountid.length > 4)
                  {
                    registfilter.push({field:'accountid',value:data.accountid})
                  }
                  if (data.user_id && data.user_id.length > 4)
                  {
                    registfilter.push({field:'user_id',value:data.user_id})
                  }
                  if (data.country && data.country.length > 4)
                  {
                    registfilter.push({field:'land',value:data.country})
                  }
                  if (data.country && data.country.length > 4)
                  {
                    kantoorfilter.push({field:'country',value:data.country})
                  }
                  if (countryobj)
                  {
                    countryobj.SetWaarde(that.overzdata.country,that.overzdata.country_txt)
                  }
                  if (kantoorobj)
                  {
                    kantoorobj.SetFilter(kantoorfilter)
                  }
                  if (regisobj)
                  {
                    regisobj.SetFilter(registfilter)
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.kantoor_txt = '';
                    data.kantoor_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    if (data.accountid && data.accountid.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.accountid})
                    }
                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                      kantoorfilter.push({field:'country',value:data.country})
                    }
                    if (kantoorobj)
                    {
                      kantoorobj.SetWaarde('','')
                      kantoorobj.SetFilter(kantoorfilter)
                    }
                    if (regisobj)
                    {
                      regisobj.SetWaarde('','')
                      regisobj.SetFilter(registfilter)
                    }
                  }
                }
                this.WorkFlowClickverder(this.state.changewfindex,true)
            }
            if (type === 'DIDMOUNT')
            {
                if (data.accountid && data.accountid.length > 4)
                {
                  registfilter.push({field:'accountid',value:data.accountid})
                }
                if (data.country && data.country.length > 4)
                {
                  registfilter.push({field:'land',value:data.country})
                  kantoorfilter.push({field:'country',value:data.country})
                }
                if (kantoorobj)
                {
                  kantoorobj.SetFilter(kantoorfilter)
                }
                if (regisobj)
                {
                  regisobj.SetFilter(registfilter)
                }
            }            
            break;

            
        case 'user_name':
        case 'user_id':
            if (data.user_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.user_name = waarde.value;
                  data.user_id = waarde.id;
                  data.accountid = '';
                  data.account_name = '';
                  data.registration_id = '';
                  data.registratie_txt = '';

                  accountfilter.push({field:'user',value:waarde.id})

                  if (accountobj)
                  {
                    accountobj.SetWaarde('','')
                    accountobj.SetFilter(accountfilter)
                  }
                  if (regisobj)
                  {
                    regisobj.SetWaarde('','')
                    registfilter.push({field:'user_id',value:waarde.id})
                    regisobj.SetFilter(registfilter)
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.user_name = '';
                    data.user_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.accountid = '';
                    data.account_name = '';
                    if (userobj)
                    {
                      userobj.SetWaarde('','')
                    }

                    if (accountobj)
                    {
                      accountobj.SetWaarde('','')
                      accountobj.SetFilter(accountfilter)
                    }
                    if (data.country && data.country.length > 4)
                    {
                      registfilter.push({field:'land',value:data.country})
                    }

                    if (regisobj)
                    {
                      regisobj.SetWaarde('','')
                      regisobj.SetFilter(registfilter)
                    }
                  }
                }
                this.WorkFlowClickverder(this.state.changewfindex,true)
            }
            break;

        default:    
          data[colId] = waarde;
          break;
      } 

      if (typeof that.setState === "function") 
      {
          that.setState({error:terug})
      }
      let wsave = await this.SaveCheck(data);
      if (wsave !== save)
      {
       if (colId === 'aang_optie' || colId === 'kz_nihil' || colId === 'corr_door')
        {
          save = false;
          this.WorkFlowClickverder(this.state.changewfindex,true)

        }
        else        
        {
          save = wsave;
          this.WorkFlowClickverder(this.state.changewfindex,true)
          wsave = this.SaveCheck(data);
          if (wsave !== save)
          {
            save = wsave;
            this.WorkFlowClickverder(this.state.changewfindex,true)
          }
        }
      }
      this.setState({data:data,save:wsave})
    }  

    componentWillUnmount() {
    }

    transition(props) {
        return <Grow in="true" { ...props }/>;
    }
    render() {
        const {classes, open, forwardedRef, ...props } = this.props;
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : '10px';
        let vld_pdf = (<form id={PdfScanVat} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='MakeVatScanPdf' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="id" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);  

        let vld_pdf_list = (<form id={PdfListing} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='MakeVatPdfListing' id="functionlist" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="idlist" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secidlist" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecidlist" name="clsecid"/>
        </form>);  

        let vld_pdf_list_klant = (<form id={PdfListingKlant} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='MakeVatAangClientPdfListing' id="functionlist" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.account_id} id="idlist" name="id"/>
        <input style={{display:'none'}} type="text" value={this.state.data && this.state.data.jaar} defaultValue={this.state.data && this.state.data.jaar} id="idjaar" name="jaar"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secidlist" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecidlist" name="clsecid"/>
        </form>);  

        let vld_pdf_onklist = (<form id={PdfOnkListing} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='MakeOnkostenPdfListing' id="functiononklist" name="function"/>
        <input style={{display:'none'}} type="text" value="" defaultValue={this.state.data.id} id={PdfOnkListingName} name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secidlist" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecidlist" name="clsecid"/>
        </form>);  


        let vld_xml_list = (<form id={XmlListing} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='MakeVatXml' id="functionlist" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="idlist" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secidlist" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecidlist" name="clsecid"/>
        </form>);  


        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                ref={"Diaref"+this.state.refid}
            >
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.state.data.description ? this.state.data.description : 'Jobdetail'}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <PerfectScrollbar className="sidebar-nav">

                <DialogContent style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <div key={"DiaKey"+this.state.refid}  ref={forwardedRef} >
                    <Grid   container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                      {this.state.data && this.state.data.id === 0 && this.state.edit === true &&
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <EditSelect className="card-title m-t-10"
                              value={this.state.data.job_txt}
                              labelText='Soort Job'
                              data={this.state.data}
                              type='AllJobDefMain'
                              colId='job_txt'
                              selectcel='job_id'
                              id={this.state.data.job_id}
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'job_txt',true)}
                            />
                          </Grid>
                        </Grid>
                      }
                      
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
                            {({getRootProps, getInputProps}) => (
                              <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                                <div {...getRootProps({className: 'dropzone'})}>
                                  <input {...getInputProps()} />
                                  <button style={{display: 'none'}} id={jobdropid} type="button" onClick={openDialog}></button>         
                                  <FileDownload formid={jobformid} frameid={jobframeid} DocData={this.state.DownloadData}/>
                                  <Timeline style={{overflowY:'auto', overflowX:'hidden', height:Math.floor( (window.innerHeight * .85) - 140) + 'px'}} stories={this.state.workflowstories} />
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.PopUpScreen}
                    {vld_pdf}
                    {vld_pdf_list}
                    {vld_pdf_list_klant}
                    {vld_pdf_onklist}
                    {vld_xml_list}

                  </div>
                </DialogContent>
              </PerfectScrollbar>

            </Dialog>
        );
    }
}


const mapStateToProps = state => ({
  ...state
});



export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobAanvrNum)));

